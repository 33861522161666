import React from "react";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";
import { H4, P } from "styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const InstructionCard = ({ thumbnail, title, description }) => {
  const clean = (dirty) => sanitizeHtml(dirty);
  const image = getImage(thumbnail);

  return (
    <>
      <GridItem>
        <GatsbyImage image={image} alt={title} />
      </GridItem>
      <GridItem>
        <H4>
          <strong>{title}</strong>
        </H4>
        <P dangerouslySetInnerHTML={{ __html: clean(description) }} />
      </GridItem>
    </>
  );
};

const GridItem = styled.div`
  grid-column: 1/7;
  width: 100%;
  min-height:200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  ${P} {
    @media (min-width: 1150px) {
      font-size: 1rem;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    border: 2px solid var(--muted-color);
    img {
      object-fit: contain !important;
    }
  }
  &:nth-child(2) {
    padding: 1rem;
    background: rgba(0, 127, 200, 0.1);
  }
  @media (min-width: 768px) {
    &:nth-child(1) {
      grid-column: 2 / span 3;
    }
    &:nth-child(2) {
      grid-column: 5 / span 7;
      padding: 1.5rem 3rem;
    }
  }
`;

export default InstructionCard;
