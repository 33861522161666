import React from "react";
import styled from "styled-components";
import { Container, Grid, P } from "styles";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProductsHeader = ({ changeProduct, products }) => {
  return (
    <Container>
      <Grid>
        <ProductSelection>
          {products.map((product, i) => {
            const image = getImage(product.mainImg);
            return (
              <Product key={i}>
                <ProductThumbnail
                  onClick={() => {
                    changeProduct(product);
                  }}
                >
                  <ImageThumb>
                    <GatsbyImage image={image} alt={product.name} loading="lazy"/>
                  </ImageThumb>
                </ProductThumbnail>
                <P>{product.shortName}</P>
              </Product>
            );
          })}
        </ProductSelection>
      </Grid>
    </Container>
  );
};

const ImageThumb = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -60%);
  width: 150%;
  max-width: 150%;
  height: 150%;
  .product-image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
    .gatsby-image-wrapper {
      max-width: 100%;

      img {
        object-fit: contain;
        max-width: 100%;
        width: 100%;
      }
    }
  }
`;

const ProductThumbnail = styled.button`
  border: none;
  outline: none;
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 100%;
  border-radius: 50%;
  background: rgba(47, 128, 237, 0.1);
  overflow: visible;
  transition: 0.375s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const Product = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:flex-start;
&:nth-child(1) {
  &:hover ${ProductThumbnail} {
    transform: rotate(-45deg) scale(1.15);
    background: var(--teva-orange);
  }
}
&:nth-child(2) {
  &:hover ${ProductThumbnail} {
    transform: rotate(-45deg) scale(1.15);
    background: var(--teva-yellow);
  }
}
&:nth-child(3) {
  &:hover ${ProductThumbnail} {
    transform: rotate(-30deg) scale(1.15);
    background: var(--teva-green);
  }
}
&:nth-child(4) {
  &:hover ${ProductThumbnail} {
    transform: rotate(-35deg) scale(1.15);
    background: var(--teva-blue);
  }
}
&:nth-child(5) {
  &:hover ${ProductThumbnail} {
    transform: rotate(-30deg) scale(1.15);
    background: var(--teva-purple);
  }
}
&:nth-child(6) {
  &:hover ${ProductThumbnail} {
    transform: rotate(-30deg) scale(1.15);
    background: var(--teva-blue);
  }
}
${P}{
  text-align:center;
  margin-top:2rem;
  font-size: .75rem;
}
@media(min-width: 768px){
  ${P}{
    font-size:.925rem;
  }
}

`;

const ProductSelection = styled.div`
  display: grid;
  grid-column: 1/7;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  margin-top: 3rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column: 2/12;
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
  }
  @media (min-width: 1150px) {
    grid-column-gap: 5rem;
    grid-row-gap: 3rem;
  }
`;

export default ProductsHeader;
