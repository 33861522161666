import * as React from "react";

function SvgPatient(props) {
  return (
    <svg
      width="81"
      height="81"
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M41.0698 16.4135C43.2738 10.0075 48.6837 2 56.0973 2C62.3087 2 68.3198 7.0047 68.3198 15.4126C68.3198 24.8214 57.0992 38.6344 41.0698 47.4427C24.84 38.6344 13.8198 24.8214 13.8198 15.4126C13.8198 7.0047 19.8308 2 26.0422 2C33.2554 2 38.8657 10.0075 41.0698 16.4135Z"
        fill="#FF620D"
        fill-opacity="0.3"
      />
      <path
        d="M44.0753 80.8739V76.2696C44.0753 73.8673 42.4724 70.8645 42.4724 64.0581C42.4724 59.654 51.0882 52.8476 51.0882 52.8476L59.3033 47.2423C64.7132 40.6361 63.1102 38.2339 71.5257 38.2339C71.5257 38.2339 70.5239 44.2395 68.5202 48.8438C68.5202 48.8438 63.7113 53.4482 60.9062 56.451"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M71.3254 38.4342V35.4314C71.3254 35.4314 69.7225 21.6184 77.3364 21.6184L78.9394 35.2312C78.9394 35.2312 77.3364 49.8449 77.3364 52.047C77.3364 55.0498 60.7059 65.6598 60.7059 73.2669V80.8741"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M37.8639 80.8739V76.2696C37.8639 73.8673 39.4669 70.8645 39.4669 64.0581C39.4669 59.654 30.851 52.8476 30.851 52.8476L22.636 47.2423C17.226 40.6361 18.829 38.2339 10.4135 38.2339C10.4135 38.2339 11.4154 44.2395 13.4191 48.8438C13.4191 48.8438 18.2279 53.4482 21.033 56.451"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M10.614 38.4342V35.4314C10.614 35.4314 12.2169 21.6184 4.60294 21.6184L3 35.2312C3 35.2312 4.60294 49.8449 4.60294 52.047C4.60294 55.0498 21.2335 65.6598 21.2335 73.2669V80.8741"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M41.0698 16.4135C43.2738 10.0075 48.6837 2 56.0973 2C62.3087 2 68.3198 7.0047 68.3198 15.4126C68.3198 24.8214 57.0992 38.6344 41.0698 47.4427C24.84 38.6344 13.8198 24.8214 13.8198 15.4126C13.8198 7.0047 19.8308 2 26.0422 2C33.2554 2 38.8657 10.0075 41.0698 16.4135Z"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SvgPatient;
