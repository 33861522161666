import React from "react";
import { Navbar, Footer, IENotSupportedSplash } from "components";
import { isIE } from "react-device-detect";
import { createGlobalStyle } from "styled-components";
import "../../fonts/font.css";

const GlobalStyles = createGlobalStyle`
    :root {
        --primary-color: rgb(0, 90, 133);
        --secondary-color: rgb(181, 209, 108);
        --muted-color:  rgba(0, 127, 200, 0.1);
        --teva-orange: rgb(255, 98, 13);
        --teva-yellow: rgb(250, 211, 31);
        --teva-green: rgb(151, 211, 38);
        --teva-blue: rgb(0, 122, 192);
        --teva-purple: rgb(106, 68, 157);
        --teva-red: rgb(220, 50, 75);
    }
    
    * {
        margin:0;
        padding:0;
        box-sizing:border-box;
    }
    html{
        font-size: 16px;
        overflow-x: hidden;
        #onetrust-consent-sdk #onetrust-pc-btn-handler, 
        #onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link 
         {
            color: #008130 !important;
            border-color: #008130 !important;
        }
    }
    body{
        font-family: 'Helvetica Neue', sans-serif;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    main{
        margin-top:5rem;
        overflow: hidden;
        @media(min-width:768px){
            margin-top:10rem;
        }
    }

    h1,h2,h3,h4,h5,h6{
        margin-block-start:0;
        margin-block-end:0;
    }
    p{
        margin-block-start:0;
        margin-block-end:0;
    }

    button{
        border:none;
        outline:none;
        background:none;
        &:focus, &:hover{
            outline:none;
            border:none;
        }
    }
    a{
        text-decoration:none;
    }
    .scroll-lock {
        overflow: hidden;
    }
`;

const Layout = ({ children }) => {
  return !isIE ? (
    <>
      <GlobalStyles />
      <Navbar />
      {children}
      <Footer />
    </>
  ) : (
    <IENotSupportedSplash />
  );
};

export default Layout;
