import React, { useState } from "react";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";
import { motion, AnimatePresence } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FeatureBPA, FeatureSafety } from "../../../images/svgs";

const FeatureButton = ({
  top,
  right,
  left,
  bottom,
  width,
  content,
  image,
  svg,
}) => {
  const [open, setOpen] = useState(false);
  const clean = (dirty) => sanitizeHtml(dirty);
  return (
    <Container top={top} left={left} right={right} bottom={bottom} open={open}>
      {/* <Dot
        open={open}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {!open && (
          <>
            <span />
            <span />
          </>
        )}
        {open && (
          <>
            {image && (
              <GatsbyImage
                image={getImage(image)}
                alt="Feature Image"
                objectFit="contain"
              />
            )}
            {svg && svg === "bpa" && <FeatureBPA />}
            {svg && svg === "safety" && (
              <FeatureSafety style={{ left: "22.5%", top: "12.5%" }} />
            )}
          </>
        )}
      </Dot> */}
      {!open && (
        <Dot open={open} onMouseEnter={() => setOpen(true)}>
          {!open && (
            <>
              <span />
              <span />
            </>
          )}
        </Dot>
      )}
      {open && (
        <Dot
          open={open}
          onMouseLeave={() => setOpen(false)}
          initial={{ scale: "0.25", x: '-50%', y: '-50%' }}
          animate={{ scale: "1", x: '-50%', y: '-50%' }}
          transition={{ duration: 0.5 }}
          exit={{ scale: "0.25", x: '-50%', y: '-50%' }}
        >
          <>
            {image && (
              <GatsbyImage
                image={getImage(image)}
                alt="Feature Image"
                objectFit="contain"
              />
            )}
            {svg && svg === "bpa" && <FeatureBPA />}
            {svg && svg === "safety" && (
              <FeatureSafety style={{ left: "22.5%", top: "12.5%" }} />
            )}
          </>
        </Dot>
      )}
      <AnimatePresence>
        {open && (
          <>
            <Content
              top={top}
              left={left}
              right={right}
              bottom={bottom}
              width={width}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0, height: 0 }}
              dangerouslySetInnerHTML={{ __html: clean(content) }}
            />
          </>
        )}
      </AnimatePresence>
    </Container>
  );
};

const Dot = styled(motion.div)`
  min-width: 2.5rem;
  width: ${({ open }) => (open ? "10rem" : "2.5rem")};
  height: ${({ open }) => (open ? "10rem" : "2.5rem")};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 50%;
  border: 0.25rem solid var(--teva-blue);
  background: ${({ open }) => (open ? "white" : "var(--teva-blue)")};
  box-shadow: 0 0 0 0 rgba(142, 173, 255, 0.1);
  animation: pulse 1s infinite;
  transform-origin: center;
  transition-duration: 0.5s;
  span {
    width: 0.875rem;
    height: 0.125rem;
    background: white;
    display: block;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:last-child {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  svg {
    position: absolute;
    display: block;
    top: 10%;
    left: 10%;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(142, 173, 255, 0.9);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

const Content = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: ${({ left }) => (left ? "2rem" : "initial")};
  right: ${({ right }) => (right ? "2rem" : "initial")};
  width: ${({ width }) => (width ? width : "600px")};
  transform: translateY(-50%);
  max-width: 80vw;
  height: auto;
  background: rgb(230, 245, 255);
  box-shadow: 4px 4px 10px rgba(0, 90, 133, 0.2);
  z-index: 1;
  padding: 1.5rem;
  padding-top: 1.5rem;
  padding-right: ${({ right }) => (right ? "4rem" : "1.5rem")};
  padding-left: ${({ left }) => (left ? "4rem" : "1.5rem")};
  padding-bottom: 0;
  border-radius: 5px;
  text-align: left;
  color: var(--primary-color);
  h4 {
    font-size: 1.5rem;
    line-height: 1.5em;
    margin-bottom: 1.5em;
  }
  p {
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: 1.5em;
  }
`;

const Container = styled.div`
  width: auto;
  height: auto;
  position: absolute;
  z-index: 3;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
`;

export default FeatureButton;
