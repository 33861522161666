import * as React from "react";

function SvgRocketFuel(props) {
  return (
    <svg
      viewBox="0 0 311 311"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M177.223 243.491c11.853-11.853 13.702-30.014 5.22-43.715 1.957-.87 3.806-2.284 5.328-3.806 6.09-6.09 7.395-15.224 3.589-22.51 2.61-.653 5.002-1.958 7.068-4.024 5.872-5.872 5.872-15.442 0-21.314l-.978-.978c3.371-3.371 4.458-8.809 2.174-13.267-2.936-5.546-9.678-7.721-15.332-4.894-5.546 2.936-7.721 9.679-4.894 15.333.218.435.544.979.87 1.305-.979.544-1.957 1.305-2.936 2.284a15.456 15.456 0 00-2.392 3.045 22.634 22.634 0 00-4.894-7.286c-.87-.87-1.849-1.631-2.827-2.393l.652-.652c4.241-4.241 4.676-10.983 1.196-15.55l.109-.109c1.523-1.523 2.61-3.263 3.262-5.22 4.785 4.35 12.071 4.241 16.638-.326.544-.544.979-.979 1.414-1.631.652 2.61 1.957 5.002 3.915 6.959 5.872 5.873 15.441 5.873 21.314 0 3.914-3.914 5.219-9.352 3.914-14.354 2.61-.217 5.111-1.196 7.177-3.262a11.354 11.354 0 000-16.094 11.354 11.354 0 00-16.094 0c-2.175 2.175-3.371 5.11-3.262 8.047-4.676-.761-9.569.652-13.158 4.241-.979.978-1.957 2.175-2.501 3.371-.544-1.631-1.523-3.045-2.719-4.241-4.676-4.676-12.288-4.676-16.964 0a11.738 11.738 0 00-2.392 3.48l-.544-.544a13.783 13.783 0 00-19.574 0 13.91 13.91 0 00-4.023 10.983c-7.177-5.655-17.617-5.22-24.359 1.305-13.375-9.896-30.666-3.697-38.387 4.893-.652-.87-1.413-1.848-2.174-2.609-9.57-9.352-24.685-9.461-34.037-.109s-9.244 24.467 0 33.71c6.742 6.743 16.42 8.591 24.793 5.655-1.305 10.44 2.175 21.314 10.222 29.361a35.36 35.36 0 0020.988 10.113c-4.241 12.506-1.523 26.969 8.482 36.973 5.111 5.111 11.309 8.265 17.943 9.678 13.919 11.962 35.015 11.31 48.173-1.848z"
        fill="#E0ECF3"
      />
      <path
        d="M213.326 151.711a12.734 12.734 0 0018.052 0 12.734 12.734 0 000-18.052 12.734 12.734 0 00-18.052 0 12.734 12.734 0 000 18.052zM109.802 102.994a12.733 12.733 0 0018.051 0 12.732 12.732 0 000-18.052 12.732 12.732 0 00-18.051 0 12.734 12.734 0 000 18.052z"
        fill="#fff"
      />
      <path
        d="M188.206 90.488c3.045 3.045 7.83 3.045 10.875 0s3.045-7.83 0-10.874c-3.045-3.045-7.83-3.045-10.875 0-3.044 3.044-3.044 7.83 0 10.874zM202.561 224.243c7.612 7.612 20.008 7.612 27.621 0 7.612-7.612 7.612-20.009 0-27.621-7.612-7.612-20.009-7.612-27.621 0-7.721 7.721-7.612 20.009 0 27.621z"
        fill="#E0ECF3"
      />
      <path
        d="M217.893 89.074a7.536 7.536 0 1010.657-10.657 7.536 7.536 0 00-10.657 10.657zM125.787 153.777c5.946 5.946 15.586 5.946 21.532 0 5.945-5.946 5.945-15.586 0-21.531-5.946-5.946-15.586-5.946-21.532 0-5.946 5.945-5.946 15.585 0 21.531z"
        fill="#fff"
      />
      <path
        d="M73.147 127.813c5.945 5.946 15.585 5.946 21.531 0 5.946-5.945 5.946-15.585 0-21.531-5.946-5.946-15.585-5.946-21.531 0-5.946 5.946-5.946 15.586 0 21.531zM169.38 114.584a4.341 4.341 0 106.139-6.14 4.341 4.341 0 00-6.139 6.14zM111.505 216.325a8.432 8.432 0 1011.92-11.923 8.432 8.432 0 00-11.92 11.923z"
        fill="#007FC8"
      />
      <path
        d="M173.852 169.436c4.024 4.024 10.548 4.024 14.572 0 4.024-4.024 4.024-10.548 0-14.572-4.024-4.023-10.548-4.023-14.572 0-4.024 4.024-4.024 10.548 0 14.572z"
        fill="#fff"
      />
      <path
        d="M246.493 87.226c-1.848 1.848-4.676 1.848-6.524 0-1.849-1.849-1.849-4.676 0-6.525 1.848-1.849 4.676-1.849 6.524 0 1.74 1.74 1.849 4.676 0 6.525z"
        fill="#E0ECF3"
      />
      <path
        d="M144.6 225.657c7.988 7.987 20.938 7.987 28.926 0 7.987-7.988 7.987-20.938 0-28.926-7.988-7.988-20.938-7.988-28.926 0-7.988 7.988-7.988 20.938 0 28.926zM78.157 212.934c10.39 10.39 27.236 10.39 37.626 0 10.39-10.39 10.39-27.236 0-37.626-10.39-10.39-27.236-10.39-37.626 0-10.39 10.39-10.39 27.236 0 37.626z"
        fill="#fff"
      />
      <path
        d="M46.622 212.281c10.39 10.39 27.235 10.39 37.625 0s10.39-27.235 0-37.625-27.235-10.39-37.625 0-10.39 27.235 0 37.625zM67.895 150.258c9.338 3.868 20.043-.567 23.911-9.905 3.868-9.338-.566-20.043-9.904-23.911-9.338-3.868-20.044.566-23.912 9.904-3.868 9.338.567 20.044 9.905 23.912zM142.643 204.125c8.828 8.829 23.142 8.829 31.97 0 8.829-8.828 8.829-23.142 0-31.97-8.828-8.829-23.142-8.829-31.97 0-8.829 8.828-8.829 23.142 0 31.97z"
        fill="#E0ECF3"
      />
      <path
        d="M156.629 188.156a8.43 8.43 0 0011.923 0 8.432 8.432 0 10-11.923 0zM209.323 179.981a4.341 4.341 0 106.139-6.14 4.341 4.341 0 00-6.139 6.14zM199.817 145.522a8.823 8.823 0 1012.477-12.48 8.823 8.823 0 00-12.477 12.48z"
        fill="#007FC8"
      />
      <path
        d="M150.845 275.375c10.098-.426 17.939-8.956 17.513-19.054-.425-10.098-8.955-17.939-19.053-17.514-10.098.426-17.939 8.956-17.514 19.054.425 10.098 8.956 17.939 19.054 17.514z"
        fill="#E0ECF3"
      />
    </svg>
  );
}

export default SvgRocketFuel;
