import React, { useState, useRef } from "react";
import ExitRampContent from "../ExitRampContent";
import ModalTrigger from "../ModalTrigger";

const ExitRamp = ({ hideCloseButton, modalLink, trigger, colors }) => {
  const [isShown, setIsShown] = useState(false);
  const triggerButton = useRef();
  const closeButton = useRef();
  const modal = useRef();

  const showModal = () => {
    setIsShown(true);
    toggleScrollLock();
  };

  const closeModal = () => {
    setIsShown(false);
    document.querySelector("html").classList.remove("scroll-lock");
  };

  const onKeyDown = (event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const onClickOutside = (e) => {
    closeModal();
  };

  const toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };

  return (
    <>
      <ModalTrigger
        showModal={showModal}
        buttonRef={triggerButton}
        trigger={trigger}
      />
      {isShown ? (
        <ExitRampContent
          buttonRef={closeButton}
          closeModal={closeModal}
          link={modalLink}
          hideCloseButton={hideCloseButton}
          modalRef={modal}
          onClickOutside={onClickOutside}
          onKeyDown={onKeyDown}
          colors={colors}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ExitRamp;
