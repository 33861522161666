import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import sanitizeHtml from "sanitize-html";
import { H4, H3 } from "styles";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { ExitRamp } from 'components';
import { Patient, Hcp, Instructions, FeatureSafety } from "images/svgs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ResourceCard = ({
  image,
  title,
  description,
  link,
  linkText,
  svg,
  externalLink,
}) => {
  const clean = (dirty) => sanitizeHtml(dirty);

  return (
    <FlexItem>
      {image && (
        <Image>
          <GatsbyImage image={getImage(image)} alt={title || "Feature"} />
        </Image>
      )}
      {svg && svg === "patient" && (
        <Image>
          <Patient style={{ left: "36%", top: "14%" }} />
        </Image>
      )}
      {svg && svg === "hcp" && (
        <Image>
          <Hcp style={{ left: "35%", top: "14%" }} />
        </Image>
      )}
      {svg && svg === "instructions" && (
        <Image>
          <Instructions style={{ left: "35%", top: "12%" }} />
        </Image>
      )}
      {svg && svg === "safety" && (
        <Image>
          <FeatureSafety
            style={{ left: "30%", top: "15%", height: "40%", width: "40%" }}
          />
        </Image>
      )}
      {title && <H3 centered>{title}</H3>}
      <Text dangerouslySetInnerHTML={{ __html: clean(description) }} />
      {link && (
        <StyledLink to={link} title={title} stripHash>
          {linkText}
        </StyledLink>
      )}
      {externalLink && (
        <ExitRamp
          trigger={<Button type="button">{linkText}</Button>}
          modalLink={externalLink}
        />
      )}
    </FlexItem>
  );
};

const StyledLink = styled(AnchorLink)`
  font-size: 0.875rem;
  line-height: 1.25em;
  font-family: "Helvetica Neue";
  font-weight: 700;
  margin: 2rem auto;
  text-align: center;
  width: auto;
  color: #007ac0;
`;

const Button = styled.button`
  font-size: 0.875rem;
  line-height: 1.25em;
  font-family: "Helvetica Neue";
  font-weight: 700;
  margin: 2rem auto;
  width: auto;
  color: #007ac0;
`;

const Text = styled.div`
  width: 100%;
  height: auto;
  p {
    width: 100%;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4em;
    font-family: "Helvetica Neue";
    color: var(--primary-color);
    text-align: center;
  }
`;

const Image = styled(motion.div)`
  width: 10rem;
  height: 10rem;
  margin-bottom: 2rem;
  z-index: 2;
  border-radius: 50%;
  border: 0.5rem solid var(--teva-blue);
  background: "white";
  box-shadow: 0 0 0 0 rgba(142, 173, 255, 0.1);
  animation: pulse 1.5s infinite;
  transform-origin: center;
  transition-duration: 0.5s;
  svg {
    position: absolute;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0.2rem rgba(0, 127, 200, 0.2),
        0 0 0 0.4rem rgba(0, 127, 200, 0.1);
    }

    50% {
      box-shadow: 0 0 0 0.375rem rgba(0, 127, 200, 0.2),
        0 0 0 0.75rem rgba(0, 127, 200, 0.1);
    }

    100% {
      box-shadow: 0 0 0 0.2rem rgba(0, 127, 200, 0.2),
        0 0 0 0.4rem rgba(0, 127, 200, 0.1);
    }
  }
`;
const FlexItem = styled.div`
  width: 300px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: relative;
  margin: 1rem auto;
  ${H3} {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.3em;
    margin-bottom: 1.3em;
  }
  @media (min-width: 1150px) {
    margin: 2rem auto;
    &:nth-child(1) {
      grid-column: 2 / span 3;
    }
    &:nth-child(2) {
      grid-column: 5 / span 3;
    }
    &:nth-child(3) {
      grid-column: 8 / span 3;
    }
  }
`;

export default ResourceCard;
