import * as React from "react";

function SvgWebIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 15A7 7 0 108 1a7 7 0 000 14z"
        stroke="#005A85"
        strokeMiterlimit={10}
      />
      <path
        d="M8 15c1.675 0 3.033-3.134 3.033-7S9.675 1 8 1C6.325 1 4.967 4.134 4.967 8S6.325 15 8 15zM1 8h14M8 1v14"
        stroke="#005A85"
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgWebIcon;
