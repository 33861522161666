import styled from "styled-components";

export const Button = styled.button`
  width: auto;
  margin: 2rem 0;
  padding: 0.75rem 2rem;
  font-size: 0.875rem;
  line-height: 1.25em;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  background: var(--primary-color);
  color: white;
  border-radius: 2.5em;
  -webkit-tap-highlight-color: transparent;
`;

export const P = styled.p`
  width: 100%;
  margin-bottom: 1em;
  font-size: 1rem;
  line-height: 1.5em;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 300;
  color: var(--primary-color);
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  small{
    font-size:.75em;
  }
  strong {
    font-weight: 700;
  }
  @media (min-width: 1150px) {
    font-size: 1.25rem;
  }
`;

export const H4 = styled.h4`
  width: 100%;
  margin-bottom: 1.3em;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--primary-color);
  line-height: 1.3em;
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  strong {
    font-weight: 700;
  }
`;

export const H3 = styled.h3`
  width: 100%;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--primary-color);
  line-height: 1.5em;
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  strong {
    font-weight: "bold";
  }
`;

export const H2 = styled.h2`
  width: 100%;
  margin: 0.5em 0;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-size: 2rem;
  color: var(--primary-color);
  line-height: 1.25em;
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  strong {
    font-weight: "normal";
  }
  @media (min-width: 1150px) {
    font-size: 2.5rem;
  }
`;

export const SmallContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 900px;
  max-width: 100%;
  padding: 1rem;
  @media (min-width: 768px){
    padding:4rem;
  }
`;

export const FlexGrid = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  margin-bottom: 2rem;
  @media (min-width: 1150px) {
    padding: 3rem;
    margin-bottom: 5rem;
  }
`;

export const TwoPieceGrid = styled.div`
  width: 100%;
  max-width: 1000px;
  display: grid;
  align-items: start;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1rem 5%;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    padding:4rem;
  }
  @media (min-width: 1150px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.25rem;
    padding: 3rem 10%;
    margin-bottom: 5rem;
  }
`;

export const ThreePieceGrid = styled.div`
  width: 100%;
  max-width: 1440px;
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1rem 5%;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    padding:4rem;
  }
  @media (min-width: 1150px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.25rem;
    padding: 3rem 10%;
    margin-bottom: 5rem;
  }
`;

export const Grid = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 0.625rem;
  grid-row-gap: 1.25rem;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 5rem;
    padding: 3rem;
    grid-column-gap: 1.25rem;
  }
  @media (min-width: 1150px) {
    grid-row-gap: 1.5rem;
    padding: 3rem;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
