import * as React from "react";

function SvgRocketShip(props) {
  return (
    <svg
      viewBox="0 0 83 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.968 49.922l2.75-1.191 3.503 6.412-3.035.713-3.218-5.934z"
        fill="#EAEAEA"
      />
      <path
        d="M51.695 54.827c.267.437-4.193 15.61-21.307 12.947-.57-.072-.771-.825-.28-1.088 2.732-1.487 8.2-5.277 8.32-11.762l13.267-.097z"
        fill="#FF620D"
      />
      <path
        d="M35.444 56.108l-2.908-6.024-3.501-5.767s-4.888-3.597-15.502 1.016C4.34 49.281 2.128 60.006 1.721 62.774c-.05.389.147.8.553 1.018 2.69 1.43 13.5 6.527 22.704 2.549 10.603-4.583 10.466-10.233 10.466-10.233z"
        fill="#FB8D3D"
      />
      <path
        d="M34.397 53.835s-1.986-3.75-1.85-3.836c-.126.055-2.23-3.671-2.23-3.671s-17.933-.42-21.553 13.348c-.084.377.114.79.53.976 14.354 6.027 25.103-6.817 25.103-6.817z"
        fill="#FFC800"
      />
      <path
        d="M33.208 51.867l-.671-1.91-1.265-1.652s-14.043.363-13.235 7.922c7.146 5.106 15.17-4.36 15.17-4.36z"
        fill="#FFEE78"
      />
      <path
        d="M32.52 49.922l2.765-1.192-3.482-6.439-2.52 1.69 3.238 5.941z"
        fill="#fff"
      />
      <path
        d="M41.583 32.495c-.219-.453-16.125-6.716-24.197 6.755-.284.437.25 1.041.776.864 2.952-.973 9.567-2.418 15.281 1.493l8.14-9.111z"
        fill="#FF620D"
      />
      <path
        d="M79.996 28.173c-4.519-2.002-28.17-10.872-48.96 12.361-.342.383-.366.918-.112 1.384l3.596 6.617 3.573 6.574c.254.467.772.793 1.288.78 33.332-.16 40.177-22.21 41.214-26.613.103-.438-.15-.904-.599-1.103z"
        fill="#fff"
      />
      <path
        d="M80.029 27.822c-1.827-.808-7.059-2.868-14.02-3.29l4.404 8.1 4.403 8.102c3.94-5.134 5.368-10.073 5.79-11.852.102-.437-.129-.862-.577-1.06zM45.914 43.26c-3.838-.121-14.662.445-23.418 10.017-.455.511-.004 1.39.738 1.357 13.861-.229 21.055-7.423 23.325-10.186.37-.474.002-1.153-.645-1.187z"
        fill="#FF620D"
      />
      <path
        d="M56.466 45.747c4.502 1.659 9.25-.4 10.604-4.598 1.354-4.198-1.198-8.946-5.7-10.604-4.502-1.658-9.25.4-10.604 4.598-1.354 4.198 1.198 8.946 5.7 10.604z"
        fill="#FFC800"
      />
      <path
        d="M57.103 43.77c3.332 1.228 6.845-.295 7.847-3.402 1.002-3.106-.886-6.62-4.217-7.847-3.332-1.227-6.845.297-7.847 3.403-1.002 3.107.886 6.62 4.217 7.847z"
        fill="#2F3B6B"
      />
      <path
        d="M57.33 43.072c2.916 1.074 5.993-.26 6.87-2.98.878-2.72-.776-5.797-3.693-6.871-2.917-1.075-5.994.26-6.871 2.98-.878 2.72.776 5.796 3.693 6.87z"
        fill="#5B66A1"
      />
      <path
        d="M62.907 40.953c-.552.239-1.237-.013-1.533-.565-.296-.551-.09-1.19.461-1.429.551-.239 1.236.013 1.532.565.297.551.09 1.19-.46 1.429zM59.83 39.528c.27.1.555-.024.636-.275.081-.252-.072-.537-.342-.637-.27-.1-.555.024-.636.276-.082.252.072.537.342.636z"
        fill="#F9F4D4"
      />
    </svg>
  );
}

export default SvgRocketShip;
