import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import defaultThumbnail from "images/social-banner.jpg";

const SeoData = ({
  children,
  lang = "en",
  meta = [],
  pageSpecificTitle,
  pageSpecificSiteTitle,
  pageSpecificDescription,
  pageSpecificThumbnail,
  pageSpecificThumbnailAlt,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultSiteTitle
            defaultDescription
            defaultThumbnailAlt
            siteUrl
            twitterPage
            author
          }
        }
      }
    `
  );
  const {
    defaultSiteTitle,
    defaultDescription,
    defaultThumbnailAlt,
    siteUrl,
    twitterPage,
    author,
  } = site.siteMetadata;
  const siteTitle = pageSpecificSiteTitle || defaultSiteTitle;
  const description = pageSpecificDescription || defaultDescription;
  const thumbnail = pageSpecificThumbnail || defaultThumbnail;
  const thumbnailAlt = pageSpecificThumbnailAlt || defaultThumbnailAlt;
  const { pathname } = useLocation();
  const url = `${siteUrl}${pathname}`;
  const image = `${siteUrl}${thumbnail}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageSpecificTitle}
      titleTemplate={`%s | ${siteTitle}`}
      link={[
        {
          rel: "canonical",
          href: url,
        },
      ]}
      meta={[
        {
          property: "og:site_name",
          content: defaultSiteTitle,
        },
        {
          name: `description`,
          content: `${description}`,
        },
        {
          property: `og:locale`,
          content: "en_GB",
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:title`,
          content: `${pageSpecificTitle} | ${defaultSiteTitle}`,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:image:url`,
          content: image,
        },
        {
          property: `og:image:secure_url`,
          content: image,
        },
        {
          property: `og:image:alt`,
          content: thumbnailAlt,
        },
        {
          property: "og:image:width",
          content: "1200",
        },
        {
          property: "og:image:height",
          content: "630",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          property: "twitter:image:alt",
          content: thumbnailAlt,
        },
        {
          name: `twitter:site`,
          content: twitterPage,
        },
        {
          name: `twitter:creator`,
          content: twitterPage,
        },
        {
          name: `twitter:title`,
          content: `${pageSpecificTitle} | ${defaultSiteTitle}`,
        },
        {
          name: `twitter:description`,
          content: `${description}`,
        },
        {
          name: `author`,
          content: author,
        },
      ].concat(meta)}
    >
      <script
        type="text/javascript"
        src="https://cdn.cookielaw.org/consent/196ef670-d2a6-4f58-bad2-9a2386e4b20f/OtAutoBlock.js"
      ></script>
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script="196ef670-d2a6-4f58-bad2-9a2386e4b20f"
      ></script>
      <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
      {children}
    </Helmet>
  );
};

export default SeoData;
