import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { H2 } from "styles";
import {
  ProductBackground,
  Galaxy,
  RocketShip,
  RocketFuel,
  Astronaut,
} from "images/svgs";

const KidsSmallMask = ({ image }) => {
  return (
    <Product>
      <PrimaryBackground
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75 }}
      >
        <ProductBackground />
      </PrimaryBackground>
      <SecondaryBackground
        initial={{ opacity: 0, scale: 0.75, y: "-50%", x: "-50%" }}
        animate={{ opacity: 1, scale: 1, y: "-50%", x: "-50%" }}
        transition={{ duration: 0.75 }}
      >
        <Galaxy />
      </SecondaryBackground>
      <Rocket
        initial={{ opacity: 0, scale: 0.75, rotate: -35, y: "-30%", x: "-60%" }}
        animate={{ opacity: 1, scale: 1, rotate: 0, y: "-50%", x: "-50%" }}
        transition={{ duration: 1.25 }}
      >
        <RocketShip />
      </Rocket>
      <Spaceteddy
        initial={{ opacity: 0, scale: 0.75, rotate: 25, y: "-30%", x: "-80%" }}
        animate={{ opacity: 1, scale: 1, rotate: 0, y: "-60%", x: "-60%" }}
        transition={{ duration: 1.25 }}
      >
        <Astronaut />
      </Spaceteddy>
      <Fuel
        initial={{ opacity: 0, scale: 0.75, y: "-70%", x: "-20%" }}
        animate={{ opacity: 1, scale: 1, y: "-50%", x: "-50%" }}
        transition={{ duration: 1.25, delay: 0.5 }}
      >
        <RocketFuel />
      </Fuel>
      <MainImage
        initial={{ opacity: 0, rotate: 180, y: "-50%", x: "-50%" }}
        animate={{ opacity: 1, rotate: 225, y: "-50%", x: "-50%" }}
        transition={{ duration: 0.75 }}
      >
        <GatsbyImage
          image={getImage(image)}
          alt="AeroChamber Kids Small Mask"
          className="product-image"
        />
      </MainImage>
      <Caption
        initial={{ transform: "rotateX(90deg) perspective(300px)" }}
        animate={{ transform: "rotateX(0deg) perspective(0px)" }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <H2>
          Small Mask,
          <br />
          <span>0-18 Months</span>
        </H2>
      </Caption>
    </Product>
  );
};
const Caption = styled(motion.div)`
  position: absolute;
  top: 85%;
  right: -10%;
  z-index: 3;
  width: auto;
  max-width: 220px;
  ${H2} {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.25em;
    margin-bottom: 0;
    width: auto;
    span {
      font-size: 0.75em;
    }
  }
  @media (min-width: 1150px) {
    top: 80%;
    ${H2} {
      font-size: 2rem;
      width: auto;
    }
  }
`;
const Spaceteddy = styled(motion.div)`
  width: 3.5rem;
  height: auto;
  position: absolute;
  top: 70%;
  left: 85%;
  transform: translate(-50%, -50%);
  z-index: 3;
  @media (min-width: 768px) {
    width: 5rem;
  }
`;

const Fuel = styled(motion.div)`
  width: 10rem;
  height: auto;
  position: absolute;
  top: 80%;
  left: 12.5%;
  transform: translate(-50%, -50%);
  z-index: 3;
  @media (min-width: 768px) {
    width: 14rem;
    top: 82.5%;
    left: 15%;
  }
`;

const Rocket = styled(motion.div)`
  width: 3rem;
  height: auto;
  position: absolute;
  top: 80%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 3;
  @media (min-width: 768px) {
    width: 4rem;
  }
`;

const SecondaryBackground = styled(motion.div)`
  width: 80%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const PrimaryBackground = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 1;
  svg {
    width: 500px;
    max-width: 100%;
    height: 100%;
  }
  width: 100%;
  @media (min-width: 1150px) {
    width: 120%;
  }
`;

const MainImage = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 100%;
  padding-bottom: 100%;
  .gatsby-image-wrapper {
    width: 120% !important;
    height: 120% !important;
    overflow: visible;
    position: absolute;
    top: -10%;
    left: -10%;
    max-width: 120%;
    max-height: 120%;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  max-width: 100%;
`;

const Product = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  @media (min-width: 768px) {
    width: 90%;
    height: 90%;
  }
  @media (min-width: 1150px) {
    width: 100%;
    height: 100%;
  }
`;

export default KidsSmallMask;
