import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Container, Grid, P, H4 } from "styles";
import { motion, AnimatePresence } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { wrap } from "@popmotion/popcorn";

const SizeGrid = ({ products }) => {
  const [modalOpen, setModalOpen] = useState(false),
    [selectedImage, setSelectedImage] = useState({}),
    maskProducts = products.filter((product) => product.hasMask === true);

  const openModal = (image, id) => {
    setModalOpen(true);
    setSelectedImage({ className: `mask-${id}`, image: getImage(image) });
  };

  const [[page, direction], setPage] = useState([0, 0]);

  const index = wrap(0, maskProducts.length, page);

  const paginate = useCallback(
    (dir) => {
      setPage([page + dir]);
    },
    [page]
  );

  useEffect(() => {
    const timer = setTimeout(() => paginate(1), 5000);
    return () => clearTimeout(timer);
  }, [paginate]);

  const mobileImage = getImage(maskProducts[index].croppedImg);

  return (
    <Container>
      <AnimatePresence>
        {modalOpen && (
          <Modal>
            <ModalContent
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.375 }}
            >
              <ModalClose
                onClick={() => {
                  setModalOpen(false);
                }}
                whileTap={{ scale: 0.9 }}
              >
                <span />
                <span />
              </ModalClose>
              <GatsbyImage
                className={selectedImage.className}
                image={selectedImage.image}
                alt="Mask Size"
              />
            </ModalContent>
          </Modal>
        )}
      </AnimatePresence>
      <Grid>
        <Products>
          <Labels>
            <Title>Mask Size Measurements</Title>
            <Width>Width</Width>
            <Height>Height</Height>
            <Age>Approx. Age Group</Age>
          </Labels>
          {maskProducts.map((product, i) => {
            const image = getImage(product.croppedImg);

            return (
              <Product key={i}>
                <ProductThumbnail>
                  <Dot
                    onClick={() => {
                      openModal(product.maskImg, product.id);
                    }}
                  >
                    <span />
                    <span />
                  </Dot>
                  <ImageThumb className={`mask-${i + 1}`}>
                    <GatsbyImage image={image} alt={product.name} />
                  </ImageThumb>
                </ProductThumbnail>
                <Title>
                  {product.shortName}
                  <br />
                  <span>Click the plus button to see mask size</span>
                </Title>
                <Width>{product.width}</Width>
                <Height>{product.height}</Height>
                <Age>{product.ageRange}</Age>
              </Product>
            );
          })}
        </Products>
        <MobileProducts>
          <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
            <MobileProduct
              key={page}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              custom={direction}
              transition={{
                x: { type: "spring", stiffness: 300, damping: 200 },
                opacity: { duration: 0.375 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={0.25}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
            >
              <ProductThumbnail>
                <ImageThumb className={`mask-${maskProducts[index].id}`}>
                  <GatsbyImage
                    image={mobileImage}
                    alt={maskProducts[index].name}
                  />
                </ImageThumb>
              </ProductThumbnail>
              <Title>{maskProducts[index].shortName}</Title>
              <div>
                <Width>
                  <strong>Width:</strong>
                  <br />
                  {maskProducts[index].width}
                </Width>
                <Height>
                  <strong>Height:</strong>
                  <br />
                  {maskProducts[index].height}
                </Height>
              </div>
              <Age>
                <strong>Approx. Age Group:</strong>
                <br />
                {maskProducts[index].ageRange}
              </Age>
            </MobileProduct>
          </AnimatePresence>
        </MobileProducts>
        <Footnote>
          *Children should transition to a mouthpiece around the age of 5.
        </Footnote>
        <Pagination>
          <Paginate
            active={maskProducts[index].id === 1}
            onClick={() => setPage([0, 0])}
          />
          <Paginate
            active={maskProducts[index].id === 2}
            onClick={() => setPage([1, 0])}
          />
          <Paginate
            active={maskProducts[index].id === 5}
            onClick={() => setPage([2, 0])}
          />
          <Paginate
            active={maskProducts[index].id === 6}
            onClick={() => setPage([3, 0])}
          />
        </Pagination>
      </Grid>
    </Container>
  );
};

const swipeConfidenceThreshold = 500;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Paginate = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  width: 2rem;
  height: 0.5rem;
  border-radius: 2px;
  border: 1px solid var(--teva-blue);
  background: ${({ active }) => (active ? "var(--teva-blue)" : "transparent")};
`;

const Pagination = styled.div`
  position: relative;
  grid-column: 1/7;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(4, 2rem);
  grid-gap: 0.5rem;
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    grid-column: 2/12;
  }
  @media (min-width: 1150px) {
    display: none;
  }
`;

const ModalClose = styled(motion.button)`
  outline: none;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0%;
  right: 20%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 50%;
  border: 0.25rem solid var(--teva-blue);
  background: var(--teva-blue);
  box-shadow: 0 0 0 0 rgba(142, 173, 255, 0.1);
  transform-origin: center;
  transition-duration: 0.5s;
  cursor: pointer;
  span {
    width: 0.875rem;
    height: 0.125rem;
    background: white;
    display: block;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    &:last-child {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`;

const ModalContent = styled(motion.div)`
  position: relative;
  width: 450px;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    &.mask-1 {
      height: 60%;
    }
    &.mask-2 {
      height: 75%;
    }
    &.mask-5 {
      height: 75%;
    }
    img {
      width: 100%;
      height: 100%;
      object-position: center !important;
      object-fit: contain !important;
    }
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dot = styled(motion.div)`
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0%;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 50%;
  border: 0.25rem solid var(--teva-blue);
  background: var(--teva-blue);
  box-shadow: 0 0 0 0 rgba(142, 173, 255, 0.1);
  animation: pulse 1s infinite;
  transform-origin: center;
  transition-duration: 0.5s;
  cursor: pointer;
  span {
    width: 0.875rem;
    height: 0.125rem;
    background: white;
    display: block;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:last-child {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(142, 173, 255, 0.9);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

const Footnote = styled(P)`
  grid-column: 1/7;
  font-size: 0.875rem;
  @media (min-width: 768px) {
    grid-column: 2/12;
    text-align: center;
    font-size: 0.925rem;
  }
  @media (min-width: 1150px) {
    text-align: left;
  }
`;

const Age = styled(P)`
  grid-column: 9/11;
`;

const Height = styled(P)`
  grid-column: 8/9;
`;

const Width = styled(P)`
  grid-column: 7/8;
`;

const Title = styled(H4)`
  font-weight: 300;
  grid-column: 4/7;
  line-height: 1.5em;
  span {
    font-size: 0.75em;
    display: inline-block;
    line-height: 1.5em;
  }
`;

const ImageThumb = styled(motion.div)`
  max-height: 100%;
  max-width: ${({ className }) =>
    className === "mask-1" ? "80%" : className === "mask-2" ? "90%" : "100%"};
  height: 180px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 300px;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    img {
      object-fit: cover;
      max-width: 100%;
      height: 100%;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }
  }
  @media (min-width: 768px) {
    hieght: auto;
  }
  @media (min-width: 1150px) {
    display:block;
    margin:0;
  }
`;

const ProductThumbnail = styled.button`
  position: relative;
  grid-column: 1/7;
  border: none;
  outline: none;
  width: 80%;
  margin: 0 auto;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  cursor: grab;

  @media (min-width: 768px) {
    width: 80%;
    grid-column: 1/4;
    align-items: flex-start;
    justify-content: flex-end;
  }
  @media (min-width: 1150px) {
    cursor: default;
  }
`;

const MobileProduct = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  padding: 3rem 1rem;
  align-items: center;
  justify-content: center;
  background: rgba(0, 127, 200, 0.1);
  border-radius: 0.3em;
  cursor: grab;
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ${Title} {
    text-align: center;
    padding: 1em 0.25em;
    margin: 0;
    font-size: 1.5rem;
  }
  ${Width} {
    text-align: center;
    padding: 1em 0.25em;
    margin: 0;
    font-size: 1rem;
  }
  ${Height} {
    text-align: center;
    padding: 1em 0.25em;
    margin: 0;
    font-size: 1rem;
  }
  ${Age} {
    text-align: center;
    padding: 1em 0.25em;
    margin: 0;
    font-size: 1rem;
  }
`;

const Product = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  align-items: start;
  justify-content: center;
  @media (min-width: 768px) {
    grid-column: 1/11;
    grid-column-gap: 1rem;
  }
  @media (min-width: 1150px) {
    grid-column: 1/11;
    grid-column-gap: 2rem;
  }
`;

const Labels = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 2rem;
  align-items: center;
  justify-content: center;
  ${Title} {
    font-weight: 600;
    font-size: 0.875rem;
  }
  ${Width} {
    font-weight: 600;
    font-size: 0.875rem;
  }
  ${Height} {
    font-weight: 600;
    font-size: 0.875rem;
  }
  ${Age} {
    font-weight: 600;
    font-size: 0.875rem;
  }
  @media (min-width: 768px) {
    grid-column: 1/11;
  }
`;

const MobileProducts = styled.div`
  grid-column: 1/7;
  width: 100%;
  display: block;
  @media (min-width: 768px) {
    grid-column: 2/12;
  }
  @media (min-width: 1150px) {
    display: none;
  }
`;

const Products = styled.div`
  display: none;
  background: rgba(47, 128, 237, 0.1);
  @media (min-width: 1150px) {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    grid-row-gap: 3rem;
    padding: 3rem;
    grid-column: 2/12;
    grid-template-columns: repeat(10, 1fr);
  }
`;

export default SizeGrid;
