import * as React from "react";

function SvgYouthMouthpieceBg(props) {
  return (
    <svg
      viewBox="0 0 445 477"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M160.896 187.354L129.762 152.319C130.593 148.839 130.112 145.136 127.887 141.565C122.977 133.726 116.709 127.973 111.916 119.732C108.164 113.342 101.453 100.288 96.1118 99.6009C92.5497 99.13 91.2366 102.072 93.022 105.139L109.297 132.06L87.4697 113.848C82.8999 110.273 77.9293 116.151 82.1539 120.19C83.6638 121.644 90.6638 126.895 96.3231 131.223C96.2184 131.35 96.1486 131.435 96.1099 131.439C95.6418 131.446 95.3543 132.099 95.7792 132.448C92.6527 132.833 91.9071 136.383 92.5946 139.011C92.6796 139.081 92.7684 139.189 92.8185 139.302C92.3238 139.038 91.723 139.683 92.1243 140.19C92.3444 140.442 92.5644 140.694 92.7844 140.946C92.4794 140.624 92.2131 140.299 91.9081 139.977C90.6282 139.673 89.3703 139.992 88.2118 140.926C84.4414 144.304 89.9779 150.167 93.0249 152.954C93.5416 153.841 94.5758 155.224 95.0553 155.333C99.8362 161.857 104.679 168.609 112.205 167.675L141.629 200.807C146.627 194.809 153.235 190.097 160.896 187.354ZM93.8762 140.526C93.7912 140.456 93.7411 140.344 93.6561 140.274C94.3549 141.026 95.1387 141.848 95.965 142.704C94.625 141.39 94.7138 141.499 93.8762 140.526ZM98.942 153.936C99.6005 153.481 100.035 152.735 99.9949 151.528C100.058 152.968 99.6195 153.675 98.942 153.936ZM100.064 135.505C100.863 136.09 101.628 136.719 102.486 137.494C101.713 136.788 100.906 136.125 100.064 135.505Z"
        fill="#97D326"
      />
      <path
        d="M116.738 106.815C120.039 109.463 122.784 112.851 124.728 116.717C126.731 120.682 128.961 124.875 131.514 127.562C133.123 129.273 134.888 130.258 136.68 130.682L166.828 183.167C169.348 180.713 172.147 178.5 175.286 176.628C178.858 174.497 182.629 172.926 186.473 171.856L161.823 128.586C158.815 123.544 157.59 117.62 158.445 111.768C158.459 111.715 158.439 111.682 158.452 111.628C158.832 109.229 158.854 108.129 160.336 105.072C161.469 102.721 167.225 96.4351 166.725 93.9268C166.607 93.273 165.675 92.4706 164.497 91.6341C161.975 89.8341 158.726 90.4592 157.293 92.9893L152.618 101.165C152.665 100.865 152.446 100.498 152.053 100.597C152.019 100.617 151.986 100.637 151.933 100.623C148.773 95.1746 146.378 90.4011 143.753 84.4069L139.304 74.2941C138.469 72.4383 136.445 71.473 134.736 72.1754C134.702 72.1954 134.669 72.2153 134.669 72.2153C132.673 73.0437 131.9 75.542 132.982 77.6584C136.491 84.5284 141.676 93.5229 141.655 99.1037C138.422 93.608 135.189 88.1123 131.975 82.65C128.941 77.4881 128.222 71.8055 123.167 74.8664C120.249 76.6525 123.012 80.9802 126.046 86.142C129.279 91.6377 132.178 97.3326 135.391 102.795C130.445 99.8152 125.311 91.9677 120.905 85.9489C119.51 84.0651 116.998 83.5719 115.374 84.948C112.768 87.1817 115.141 90.0215 116.676 92.139C120.297 97.2224 124.639 101.921 128.573 106.772L115.558 96.3368C113.742 94.8853 111.294 95.2591 110.175 97.1032C109.151 98.8009 109.658 101.169 111.308 102.494L116.738 106.815ZM152.464 101.438C152.497 101.418 152.497 101.418 152.531 101.398L152.464 101.438Z"
        fill="#FF620D"
      />
      <path
        d="M213.36 72.4744L206.536 78.0432C206.207 78.4268 205.814 78.705 205.384 78.8483C205.342 78.6585 205.22 78.5024 204.967 78.4391C204.242 78.3293 203.525 78.3291 202.808 78.3289C202.572 78.1264 202.416 77.8902 202.256 77.5992C201.185 75.5031 200.933 71.4969 199.833 69.3754C199.057 67.8908 198.173 64.9809 196.71 63.8799C194.315 62.1334 189.667 63.7303 188.68 67.0319C188.49 66.7156 188.296 68.1367 188.219 69.3005C188.186 69.2204 188.177 69.1108 188.144 69.0306C188.135 68.921 188.08 68.9252 188.085 68.98C185.121 66.2845 181.316 69.1679 181.273 72.5626C181.033 72.6637 180.83 72.8998 180.965 73.2203C181.062 73.4059 181.134 73.621 181.231 73.8066C179.06 71.0228 174.927 72.4976 173.927 75.2763C173.585 75.137 173.032 75.4827 173.18 75.9677C173.365 76.5876 173.589 77.3425 173.867 78.0932L168.222 70.6403C166.267 66.7095 158.402 66.2646 161.777 75.0494C163.741 80.1652 165.946 82.6707 167.05 84.4885C170.494 90.2076 174.063 99.0053 180.037 104.641L185.387 171.561C192.228 167.287 200.051 165.033 207.979 164.867L203.581 102.092C206.973 97.0618 209.412 92.5461 209.733 88.8268C210.004 85.525 217.065 81.2341 219.515 79.0335C223.822 75.1466 223.295 73.6705 221.794 72.0761C219.48 69.579 215.474 69.8309 213.36 72.4744Z"
        fill="#007FC8"
      />
      <path
        d="M261.876 79.3497C259.716 77.6774 257.535 76.0377 255.408 74.3864C251.395 71.2852 244.911 63.7663 239.81 68.1544C238.847 69.4302 238.633 71.0509 239.14 72.4886C239.086 72.5002 239.086 72.5002 239.163 72.5961C239.163 72.5961 239.163 72.5961 239.196 72.6172C239.196 72.6172 239.196 72.6172 239.163 72.5961C238.929 72.8152 237.842 72.9929 238.278 73.3208C234.561 72.7712 232.898 77.066 234.356 80.1828C234.323 80.1618 234.291 80.1407 234.291 80.1407C233.759 79.8896 233.325 80.4891 233.472 80.9075C230.305 80.4361 227.52 83.96 228.556 86.9429C228.168 86.9701 227.842 87.5465 228.182 87.9513C228.215 87.9724 228.215 87.9724 228.227 88.0261C223.087 86.0953 222.134 92.515 224.703 96.349C225.32 97.2566 226.369 98.2119 227.547 99.1114C228.56 99.7652 228.235 99.6944 228.616 99.8938C229.495 100.323 229.49 100.69 230.391 101.086C231.371 101.719 232.306 102.277 233.046 102.708L233.078 102.73C235.501 104.711 236.401 107.976 235.323 110.936L219.356 158.522C227.043 157.655 234.997 158.811 242.346 162.123C248.55 142.34 256.823 116.629 259.267 109.691C259.267 109.691 268.875 103.771 272.197 82.3588C273.289 75.1498 279.525 70.9392 276.073 67.1828C268.974 59.5435 265.097 73.9322 261.876 79.3497Z"
        fill="#97D326"
      />
      <path
        d="M149.602 201.546L108.749 184.62C109.028 183.792 109.228 182.928 109.191 181.996C109.031 178.889 106.017 177.412 104.746 175.75C104.744 175.711 104.744 175.711 104.704 175.673L104.743 175.672C104.702 175.634 104.699 175.557 104.659 175.519C104.615 175.404 104.612 175.327 104.608 175.21L104.488 175.137C103.366 172.302 104.676 165.986 104.922 162.319C105.054 160.719 105.178 158.924 105.312 157.363C105.498 155.138 103.484 153.388 101.324 153.861C100.745 154 100.13 154.18 99.5531 154.358C95.2566 155.926 94.6941 165.442 94.7495 170.848C94.3343 171.176 93.9206 171.542 93.5069 171.908C91.9529 171.969 89.7082 171.278 86.2288 169.858C81.8796 168.082 61.8626 156.646 57.5272 158.216C56.223 158.695 55.3448 162.114 55.7742 163.148C56.5463 164.985 66.3506 168.96 70.936 170.805C70.9748 170.803 70.9733 170.764 71.0121 170.763C70.9733 170.764 70.9748 170.803 70.936 170.805C70.7104 171.008 70.6494 171.438 71.0455 171.617C71.1636 171.652 71.2428 171.688 71.3608 171.722C69.9729 172.048 68.9185 172.945 68.2364 174.412C67.4202 176.428 68.0757 178.27 69.487 179.538C69.2181 179.626 69.0763 179.982 69.2423 180.248C69.2438 180.286 69.2827 180.285 69.323 180.322C69.2827 180.285 69.2827 180.285 69.2423 180.248C64.8524 180.419 62.8407 187.695 67.4276 189.579L51.0949 182.823C49.5121 182.146 47.6731 182.879 46.9568 184.463C45.8808 186.801 45.6865 186.808 48.0514 188.584C51.4597 191.174 63.5178 196.073 66.6818 197.389C74.077 200.447 81.842 205.007 89.5116 207.12C92.7755 208.005 95.3693 207.67 97.462 206.46L137.445 223.031C139.419 214.783 143.68 207.341 149.602 201.546Z"
        fill="#FFC800"
      />
      <path
        d="M292.432 241.949L333.761 257.675C333.507 258.51 333.332 259.38 333.396 260.31C333.647 263.411 336.703 264.8 338.022 266.424C338.025 266.463 338.025 266.463 338.066 266.499L338.028 266.502C338.069 266.538 338.074 266.616 338.116 266.652C338.162 266.765 338.168 266.843 338.176 266.959L338.297 267.029C339.502 269.83 338.377 276.181 338.238 279.854C338.153 281.458 338.081 283.255 337.993 284.82C337.873 287.049 339.937 288.74 342.082 288.204C342.656 288.047 343.266 287.85 343.837 287.655C348.086 285.962 348.37 276.434 348.157 271.032C348.563 270.692 348.966 270.314 349.368 269.936C350.92 269.83 353.184 270.454 356.703 271.773C361.102 273.421 381.445 284.267 385.733 282.571C387.022 282.055 387.8 278.611 387.341 277.59C386.515 275.776 376.599 272.089 371.962 270.38C371.923 270.382 371.926 270.421 371.887 270.424C371.926 270.421 371.923 270.382 371.962 270.38C372.181 270.17 372.23 269.738 371.829 269.57C371.71 269.539 371.629 269.506 371.51 269.475C372.888 269.108 373.916 268.181 374.555 266.695C375.312 264.656 374.603 262.834 373.155 261.608C373.421 261.512 373.553 261.152 373.379 260.891C373.376 260.852 373.338 260.855 373.296 260.819C373.338 260.855 373.338 260.855 373.379 260.891C377.762 260.592 379.56 253.26 374.92 251.511L391.443 257.786C393.045 258.417 394.862 257.631 395.532 256.026C396.539 253.658 396.733 253.645 394.317 251.94C390.835 249.45 378.639 244.905 375.438 243.682C367.956 240.842 360.061 236.51 352.333 234.622C349.045 233.833 346.462 234.244 344.406 235.514L303.956 220.118C302.224 228.42 298.181 235.983 292.432 241.949Z"
        fill="#FFC800"
      />
      <path
        d="M183.263 310L200.566 363.707C198.597 366.695 197.785 370.339 198.657 374.456C200.596 383.5 204.523 391.047 206.215 400.43C207.56 407.717 209.411 422.277 214.196 424.746C217.384 426.405 219.622 424.089 218.991 420.596L212.885 389.736L227.183 414.306C230.258 419.226 236.937 415.398 234.345 410.159C233.422 408.278 228.635 400.952 224.793 394.952C224.935 394.868 225.03 394.812 225.068 394.822C225.51 394.975 226.003 394.459 225.723 393.986C228.793 394.692 230.706 391.61 230.957 388.905C230.901 388.81 230.854 388.678 230.846 388.555C231.22 388.972 232.005 388.571 231.801 387.957C231.681 387.645 231.56 387.334 231.439 387.022C231.616 387.428 231.755 387.825 231.932 388.231C233.031 388.954 234.322 389.084 235.73 388.602C240.427 386.714 237.225 379.313 235.313 375.653C235.13 374.643 234.63 372.99 234.217 372.723C231.95 364.959 229.704 356.96 222.311 355.268L205.966 303.933C199.221 307.865 191.401 310.036 183.263 310ZM230.27 387.043C230.326 387.138 230.334 387.261 230.39 387.355C229.99 386.41 229.534 385.37 229.05 384.283C229.861 385.975 229.814 385.843 230.27 387.043ZM230.086 372.709C229.312 372.912 228.649 373.465 228.275 374.613C228.707 373.239 229.36 372.724 230.086 372.709ZM222.739 389.651C222.188 388.827 221.683 387.976 221.142 386.954C221.627 387.881 222.16 388.78 222.739 389.651Z"
        fill="#007FC8"
      />
      <path
        d="M277.843 329.134C274.466 326.583 271.623 323.277 269.568 319.47C267.449 315.564 265.098 311.438 262.467 308.827C260.808 307.164 259.016 306.231 257.212 305.859L228.892 261.392C226.445 263.918 223.712 266.212 220.629 268.176C217.12 270.41 213.396 272.09 209.586 273.272L232.141 308.689C235.295 313.641 236.693 319.526 236.009 325.401C235.997 325.454 236.018 325.487 236.006 325.541C235.697 327.95 235.706 329.051 234.315 332.149C233.251 334.532 227.68 340.984 228.254 343.477C228.391 344.127 229.345 344.901 230.547 345.703C233.121 347.429 236.35 346.709 237.709 344.138L242.143 335.829C242.105 336.13 242.334 336.491 242.725 336.381C242.758 336.36 242.79 336.339 242.844 336.351C246.162 341.705 248.695 346.406 251.494 352.321L256.236 362.3C257.125 364.131 259.177 365.036 260.864 364.284C260.897 364.263 260.93 364.242 260.93 364.242C262.901 363.356 263.601 360.836 262.458 358.752C258.749 351.988 253.304 343.149 253.162 337.57C256.554 342.969 259.946 348.367 263.318 353.734C266.501 358.805 267.386 364.464 272.35 361.257C275.214 359.386 272.326 355.141 269.143 350.07C265.751 344.671 262.686 339.063 259.315 333.697C264.346 336.531 269.708 344.225 274.287 350.113C275.737 351.955 278.262 352.375 279.845 350.952C282.385 348.643 279.93 345.873 278.334 343.802C274.566 338.826 270.089 334.257 266.014 329.523L279.328 339.573C281.186 340.971 283.622 340.526 284.686 338.65C285.661 336.923 285.084 334.57 283.396 333.295L277.843 329.134ZM242.289 335.552C242.256 335.573 242.256 335.573 242.224 335.594L242.289 335.552C242.289 335.552 242.268 335.519 242.289 335.552Z"
        fill="#FF620D"
      />
      <path
        d="M290.123 318.939L294.527 311.31C294.698 310.835 294.966 310.436 295.317 310.149C295.424 310.311 295.594 310.414 295.853 310.383C296.57 310.229 297.24 309.974 297.91 309.72C298.203 309.826 298.433 309.991 298.686 310.206C300.431 311.786 302.088 315.442 303.869 317.035C305.121 318.148 306.981 320.554 308.74 321.064C311.598 321.847 315.377 318.705 315.128 315.267C315.418 315.496 315.095 314.098 314.753 312.983C314.813 313.046 314.86 313.146 314.92 313.209C314.967 313.308 315.017 313.285 314.993 313.235C318.721 314.703 321.254 310.657 320.09 307.468C320.278 307.288 320.384 306.996 320.144 306.744C319.988 306.605 319.844 306.429 319.688 306.29C322.705 308.122 326.046 305.276 325.995 302.324C326.364 302.333 326.757 301.813 326.448 301.412C326.054 300.898 325.577 300.272 325.051 299.669L332.973 304.633C336.197 307.614 343.707 305.239 337.434 298.224C333.782 294.138 330.832 292.578 329.154 291.271C323.905 287.146 317.445 280.188 309.86 277.04L281.107 216.374C276.229 222.798 269.715 227.682 262.362 230.651L288.753 287.779C287.368 293.685 286.69 298.772 287.71 302.364C288.629 305.547 283.551 312.064 282.041 314.991C279.394 320.153 280.41 321.347 282.379 322.304C285.429 323.818 289.085 322.16 290.123 318.939Z"
        fill="#007FC8"
      />
      <path
        d="M154.099 410.805C156.638 411.813 159.188 412.784 161.689 413.781C166.405 415.649 174.718 421.076 178.404 415.446C178.975 413.953 178.731 412.337 177.846 411.096C177.895 411.07 177.895 411.07 177.794 410.999C177.794 410.999 177.794 410.999 177.757 410.988C177.757 410.988 177.757 410.988 177.794 410.999C177.958 410.723 178.953 410.252 178.443 410.057C182.168 409.555 182.575 404.968 180.31 402.377C180.347 402.388 180.385 402.399 180.385 402.399C180.965 402.493 181.216 401.797 180.959 401.436C184.132 401.011 185.831 396.853 184.009 394.274C184.375 394.141 184.528 393.497 184.089 393.202C184.052 393.191 184.052 393.191 184.026 393.142C189.499 393.573 188.635 387.141 185.104 384.169C184.26 383.468 182.987 382.841 181.606 382.303C180.452 381.955 180.783 381.933 180.363 381.847C179.398 381.679 179.302 381.325 178.326 381.193C177.21 380.857 176.156 380.58 175.326 380.371L175.289 380.36C172.411 379.127 170.642 376.239 170.858 373.097L173.01 322.949C165.864 325.913 157.901 327.007 149.922 325.862C149.445 346.589 148.622 373.586 148.197 380.929C148.197 380.929 140.605 389.281 143.349 410.774C144.298 418.004 139.472 423.778 143.83 426.43C152.769 431.803 152.506 416.903 154.099 410.805Z"
        fill="#97D326"
      />
    </svg>
  );
}

export default SvgYouthMouthpieceBg;
