import React, { useState } from "react";
import { InstructionCard } from "components";
import { Grid } from "styles";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { Button } from "styles";

const Instructions = ({ instructions }) => {
  const [selected, setSelected] = useState(0);
  const { steps } = instructions[selected];
  return (
    <>
      <Grid style={{marginBottom:0}}>
        <ButtonRow>
          <StyledButton
            active={selected === 0}
            onClick={() => {
              setSelected(0);
            }}
          >
            With Mouthpiece
          </StyledButton>
          <StyledButton
            active={selected === 1}
            onClick={() => {
              setSelected(1);
            }}
          >
            With Mask
          </StyledButton>
        </ButtonRow>
      </Grid>

      <AnimatePresence>
        <AnimatedGrid
          key={selected}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.75 }}
        >
          {steps.map((step, i) => (
            <Grid
              key={i}
              style={{ gridGap: 0, marginBottom: "2rem", padding: "1rem" }}
            >
              <InstructionCard
                thumbnail={step.thumbnail}
                title={step.title}
                description={step.description}
              />
            </Grid>
          ))}
        </AnimatedGrid>
      </AnimatePresence>
    </>
  );
};

const StyledButton = styled(Button)`
  border-radius: 0px;
  background: ${({ active }) => (active ? "#007AC0" : "white")};
  color: ${({ active }) => (active ? "white" : "#007AC0")};
  transition-duration: 0.25s;
  cursor: pointer;
  border: 1px solid #007AC0 !important;
  border-radius:.25rem;
  line-height:1.5em;
  &:hover {
    border: 1px solid #007AC0;
  background: ${({ active }) => (active ? "#007AC0" : "rgba(0, 127, 200, 0.1)")};
  }
  &:last-child {
    margin-left: 1rem;
  }
`;

const ButtonRow = styled.div`
  width: 100%;
  grid-column: 1/7;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    grid-column: 2/12;
  }
`;

const AnimatedGrid = styled(motion.div)`
  width: 100%;
  height: auto;
`;

export default Instructions;
