import * as React from "react";

function SvgFeatureBPA(props) {
  return (
    <svg
      width="119"
      height="110"
      viewBox="0 0 119 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.912638 88.8546C-7.01322 69.7536 38.7199 0 59.4 0C80.0801 0 125.813 69.7536 117.887 88.8546C106.712 115.881 12.1182 115.881 0.912638 88.8546Z"
        fill="#97D326"
      />
      <path
        d="M64.6232 25.8729C58.5497 31.9463 48.2856 42.2105 48.2248 49.3771C55.27 50.1363 62.5582 47.8588 67.9636 42.4534C74.2799 36.137 76.3753 27.1483 74.2192 19.101C66.1415 16.9449 57.1832 19.0403 50.8668 25.3566C45.2488 30.9746 42.9713 38.6271 44.0342 45.9153C51.049 32.8877 64.6232 25.8729 64.6232 25.8729Z"
        fill="white"
      />
      <path
        d="M46.9494 54.4789C47.6479 54.4789 48.2856 54.5397 48.8626 54.6612C49.4395 54.7826 49.9254 54.9952 50.3506 55.2685C50.7757 55.5418 51.0794 55.9366 51.3223 56.3921C51.5653 56.8476 51.6564 57.4549 51.6564 58.123C51.6564 58.8822 51.4742 59.4895 51.1401 59.9754C50.8061 60.4613 50.2898 60.8864 49.6521 61.1901C50.5631 61.4634 51.2312 61.9189 51.6867 62.5566C52.1422 63.2247 52.3548 64.0143 52.3548 64.9253C52.3548 65.6845 52.203 66.3222 51.9297 66.8688C51.626 67.4154 51.2616 67.8709 50.7453 68.205C50.2595 68.539 49.6825 68.8123 49.0751 68.9641C48.4374 69.116 47.7997 69.2071 47.1316 69.2071H39.965V54.4182H46.9494V54.4789ZM46.5243 60.4613C47.1013 60.4613 47.5871 60.3095 47.9516 60.0362C48.316 59.7629 48.4982 59.3073 48.4982 58.7C48.4982 58.366 48.4374 58.0623 48.316 57.8497C48.1945 57.6371 48.0123 57.4549 47.8301 57.3335C47.6479 57.212 47.3746 57.1209 47.1013 57.0905C46.828 57.0298 46.5547 57.0298 46.251 57.0298H43.2143V60.4917H46.5243V60.4613ZM46.7065 66.7473C47.0102 66.7473 47.3138 66.717 47.6175 66.6562C47.9212 66.5955 48.1641 66.5044 48.3767 66.3525C48.5893 66.2007 48.7715 66.0185 48.8929 65.7756C49.0144 65.5326 49.0751 65.1986 49.0751 64.8342C49.0751 64.075 48.8626 63.5284 48.4374 63.1944C48.0123 62.8603 47.4353 62.7085 46.7369 62.7085H43.1839V66.7777H46.7065V66.7473Z"
        fill="white"
      />
      <path
        d="M61.2221 54.4788C62.1331 54.4788 62.9226 54.6002 63.5907 54.8735C64.2284 55.1468 64.775 55.5112 65.1698 55.9364C65.5646 56.3919 65.8682 56.8778 66.0505 57.4548C66.2327 58.0317 66.3238 58.6087 66.3238 59.2161C66.3238 59.8234 66.2327 60.4004 66.0505 60.9774C65.8682 61.5543 65.5646 62.0706 65.1698 62.4957C64.775 62.9512 64.2284 63.2853 63.5907 63.5586C62.953 63.8319 62.1634 63.9534 61.2221 63.9534H57.7906V69.2676H54.5413V54.4788H61.2221ZM60.3414 61.4329C60.7058 61.4329 61.0702 61.4025 61.4043 61.3418C61.7383 61.281 62.042 61.1899 62.3153 61.0077C62.5886 60.8559 62.8012 60.6129 62.953 60.3396C63.1048 60.036 63.1959 59.6716 63.1959 59.1857C63.1959 58.7302 63.1048 58.3354 62.953 58.0317C62.8012 57.7281 62.5886 57.5155 62.3153 57.3636C62.042 57.2118 61.7383 57.0903 61.4043 57.0296C61.0702 56.9689 60.7058 56.9385 60.3414 56.9385H57.8209V61.3721H60.3414V61.4329Z"
        fill="white"
      />
      <path
        d="M74.2192 54.4788L79.7461 69.2676H76.3753L75.2517 65.988H69.7249L68.5709 69.2676H65.2913L70.8788 54.4788H74.2192ZM74.4014 63.5586L72.549 58.1228H72.5187L70.6055 63.5586H74.4014Z"
        fill="white"
      />
      <path
        d="M43.8823 75.8877V78.6207H36.746V82.0523H42.9409V84.5727H36.746V90.6766H33.4967V75.8877H43.8823Z"
        fill="white"
      />
      <path
        d="M53.7213 75.8877C54.3894 75.8877 54.9664 76.0092 55.513 76.2217C56.0292 76.4343 56.5151 76.738 56.8795 77.1024C57.2743 77.4668 57.5476 77.8919 57.7602 78.4082C57.9727 78.8941 58.0638 79.4407 58.0638 79.9873C58.0638 80.8679 57.8816 81.5967 57.5172 82.2345C57.1528 82.8722 56.5455 83.358 55.7256 83.6921V83.7225C56.1203 83.8439 56.4544 83.9958 56.7277 84.2387C57.001 84.4513 57.2136 84.7246 57.3654 85.0586C57.5172 85.3623 57.6387 85.6963 57.7298 86.0911C57.7905 86.4555 57.8513 86.8503 57.8816 87.2147C57.8816 87.4576 57.912 87.7309 57.912 88.0346C57.912 88.3383 57.9424 88.6723 57.9727 89.0064C58.0031 89.3404 58.0638 89.6441 58.1246 89.9477C58.1853 90.2514 58.3068 90.4944 58.4586 90.7069H55.2093C55.0271 90.2514 54.9057 89.6744 54.8753 89.0367C54.8449 88.399 54.7842 87.7613 54.6931 87.1843C54.5716 86.4251 54.359 85.8482 53.9946 85.4838C53.6302 85.1193 53.0532 84.9371 52.2333 84.9371H48.984V90.7069H45.7347V75.9181H53.7213V75.8877ZM52.5674 82.5685C53.3266 82.5685 53.8732 82.4167 54.2376 82.0826C54.602 81.7486 54.7842 81.202 54.7842 80.4732C54.7842 79.7443 54.602 79.2281 54.2376 78.8941C53.8732 78.56 53.2962 78.4082 52.5674 78.4082H49.0144V82.5685H52.5674Z"
        fill="white"
      />
      <path
        d="M71.7595 75.8878V78.6208H63.9551V81.779H71.1218V84.2995H63.9551V87.9132H71.9417V90.6463H60.7058V75.8574H71.7595V75.8878Z"
        fill="white"
      />
      <path
        d="M85.1818 75.8878V78.6208H77.3774V81.779H84.5441V84.2995H77.3774V87.9132H85.364V90.6463H74.1281V75.8574H85.1818V75.8878Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgFeatureBPA;
