import * as React from "react";

function SvgBalloon(props) {
  return (
    <svg
      viewBox="0 0 85 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.98 104.856l1.426-5.046-.347-.077-1.078 3.814v-3.775h-.347v3.852l-1.078-3.89-.347.076 1.464 5.162.154-.077.154.077V104.856zM45.53 104.856l1.386-5.046-.308-.077-1.079 3.814v-3.775h-.347v3.852l-1.078-3.89-.347.076 1.425 5.162.154-.077.154.077.04-.077v-.039z"
        fill="#005A85"
      />
      <path
        d="M45.914 5.94c-1.964 0-3.312.116-3.852.154-.5-.038-1.887-.154-3.851-.154C27.656 5.902 0 8.483 0 36.486c0 23.766 23.92 49.342 35.784 60.551H48.38c11.863-11.248 35.783-36.785 35.783-60.551 0-28.003-27.656-30.584-38.249-30.546z"
        fill="#FFC800"
      />
      <path
        d="M42.563 3.976c-3.159-.5-36.94-4.969-36.94 31.74 0 20.183 17.334 44.758 31.624 60.55H47.88c14.29-15.792 31.623-40.367 31.623-60.55 0-36.709-33.819-32.24-36.939-31.74z"
        fill="#fff"
      />
      <path
        d="M42.563 1.164c-2.62-.5-26.616-4.083-26.616 34.551 0 20.03 14.483 47.494 23.419 61.322h6.433c8.897-13.828 23.38-41.292 23.38-61.322 0-38.634-23.997-35.052-26.616-34.55z"
        fill="#FFC800"
      />
      <path
        d="M42.794 95.15c2.465-5.855 12.288-34.128 12.288-59.204C55.081.432 44.411-.107 42.563.01c-1.849-.116-12.519.423-12.519 35.937 0 25.076 9.823 53.349 12.288 59.204v1.078s.077-.192.231-.54c.154.347.231.54.231.54V95.15z"
        fill="#fff"
      />
      <path
        d="M36.092 100.234c2.812.039 9.129.039 11.94 0 .502 0 1.156-.385 1.465-.809 1.656-2.504 7.048-10.477 10.67-14.599 0 0-8.128 2.08-18.105 2.08-8.82 0-18.103-2.08-18.103-2.08 3.428 3.89 8.975 12.057 10.67 14.6.307.423.962.808 1.463.808z"
        fill="#FF620D"
      />
      <path
        d="M47.493 110.75l-.924-4.083a.593.593 0 00-.578-.578h-7.819a.593.593 0 00-.578.578l-.924 4.083a.386.386 0 01-.77 0l.924-5.509c0-.308.27-.577.578-.577h9.437c.308 0 .578.269.578.577l.924 5.509a.386.386 0 01-.385.385c-.27 0-.463-.154-.463-.385z"
        fill="#005A85"
      />
      <path
        d="M50.305 110.133c-2.041.27-4.93.463-8.166.463-3.235 0-6.124-.193-8.166-.463-.616-.077-1.117.308-1.117.963v9.245c.154 2.041 1.926 3.659 4.083 3.659h10.4c2.157 0 3.93-1.618 4.083-3.659v-9.245c0-.616-.5-1.04-1.117-.963z"
        fill="#FF620D"
      />
      <path
        d="M49.496 113.061l-.077-.039h-.154c-.77.616-2.041 1.002-3.466 1.002-1.503 0-2.85-.424-3.583-1.079v-.038c-.115 0-.192-.039-.308-.039 0 0 .039.039.039.077h-.039l.039.039h-.039c-.732.616-2.041 1.04-3.543 1.04-1.426 0-2.735-.386-3.467-1.002h-.27c-.423.347-1.04.539-1.81.539v.386c.809-.039 1.464-.231 1.926-.617.809.655 2.118 1.079 3.62 1.079 1.541 0 2.928-.462 3.698-1.156.77.694 2.157 1.156 3.698 1.156 1.502 0 2.812-.424 3.62-1.079.463.386 1.195.617 2.042.617v-.424c-.808.077-1.502-.116-1.926-.462z"
        fill="#fff"
      />
      <path
        d="M42.602 113.484c0-.038.038-.077.038-.115 0-.077-.038-.116-.077-.154.116-.116.27-.27.27-.424a.445.445 0 00-.462-.462.445.445 0 00-.463.462c0 .154.154.308.27.424a.292.292 0 00-.077.192c0 .039 0 .077.038.116-.616.462-2.388 1.849-2.388 3.043a2.622 2.622 0 002.62 2.619 2.622 2.622 0 002.619-2.619c.038-1.233-1.772-2.619-2.388-3.082zM35.283 113.484c0-.038.039-.077.039-.115 0-.077-.039-.116-.077-.154.115-.116.27-.27.27-.424a.445.445 0 00-.463-.462.445.445 0 00-.462.462c0 .154.154.308.27.424a.292.292 0 00-.078.192c0 .039 0 .077.039.116-.616.462-2.388 1.849-2.388 3.043a2.622 2.622 0 002.619 2.619 2.622 2.622 0 002.62-2.619c.038-1.233-1.773-2.619-2.389-3.082zM49.92 113.484c0-.038.039-.077.039-.115 0-.077-.039-.116-.077-.154.115-.116.27-.27.27-.424a.445.445 0 00-.463-.462.445.445 0 00-.462.462c0 .154.154.308.27.424a.292.292 0 00-.078.192c0 .039 0 .077.039.116-.616.462-2.388 1.849-2.388 3.043a2.622 2.622 0 002.62 2.619c.654 0 1.232-.231 1.694-.655v-3.736a8.177 8.177 0 00-1.464-1.31z"
        fill="#2D1712"
      />
      <path
        d="M34.975 113.33c0-.038.038-.077.038-.115 0-.077-.038-.116-.077-.154.116-.116.309-.27.309-.424a.445.445 0 00-.463-.462.445.445 0 00-.462.462c0 .154.154.308.27.424a.291.291 0 00-.077.192c0 .039 0 .077.038.116-.616.462-2.388 1.849-2.388 3.004a2.622 2.622 0 002.62 2.62 2.622 2.622 0 002.619-2.62c0-1.194-1.81-2.58-2.427-3.043zM42.294 113.33c0-.038.038-.077.038-.115 0-.077-.038-.116-.077-.154.115-.116.27-.27.27-.424a.445.445 0 00-.463-.462.445.445 0 00-.462.462c0 .154.154.308.27.424a.292.292 0 00-.077.192c0 .039 0 .077.038.116-.616.462-2.388 1.849-2.388 3.004a2.622 2.622 0 002.62 2.62 2.622 2.622 0 002.619-2.62c.038-1.194-1.772-2.58-2.389-3.043zM49.612 113.33c0-.038.039-.077.039-.115 0-.077-.039-.116-.077-.154.115-.116.27-.27.27-.424a.445.445 0 00-.463-.462.445.445 0 00-.462.462c0 .154.154.308.27.424a.292.292 0 00-.078.192c0 .039 0 .077.039.116-.616.462-2.388 1.849-2.388 3.004a2.622 2.622 0 002.619 2.62 2.622 2.622 0 002.62-2.62c.038-1.194-1.773-2.58-2.389-3.043z"
        fill="#007FC8"
      />
      <path d="M45.568 104.664h-7.01v1.425h7.01v-1.425z" fill="#FF620D" />
    </svg>
  );
}

export default SvgBalloon;
