import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { Arrow, Patient, Hcp } from "images/svgs";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Dropdown = ({ toggleNav }) => {
  const [expanded, setExpanded] = useState(0);
  const dropdownToggle = () => {
    setExpanded(expanded === 0 ? 1 : 0);
  };

  return (
    <Container expanded={expanded === 1 ? true : false}>
      <Heading
        onClick={dropdownToggle}
        expanded={expanded === 1 ? true : false}
      >
        Resources
        <DownArrow expanded={expanded === 0 ? 1 : 0} />
      </Heading>

      <ContentContainer expanded={expanded === 1 ? true : false}>
        <AnimatePresence initial={false}>
          {expanded && (
            <Content
              onClick={() => {
                dropdownToggle();
                toggleNav();
              }}
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <AnchorLink
                to="/resources/patients#patients"
                title="Patients and Caregivers"
                stripHash
              >
                <span>
                  <Patient />
                </span>
                Patients & Caregiver Info
              </AnchorLink>
              <AnchorLink
                to="/resources/healthcare-professionals#hcp"
                title="HCP Resources"
                stripHash
              >
                <span>
                  <Hcp />
                </span>
                HCP Resources
              </AnchorLink>
              {/* <AnchorLink
                to="/resources/patients#covid"
                title="Covid 19 News"
                stripHash
              >
                <span>
                  <Covid />
                </span>
                Covid 19 News
              </AnchorLink> */}
            </Content>
          )}
        </AnimatePresence>
      </ContentContainer>
    </Container>
  );
};

const DownArrow = styled(Arrow)`
  margin-right: 0;
  margin-left: 0.5rem;
  width: 1.25rem;
  height: auto;
  min-width: 1.25rem;
  height: auto;
  transition-duration: 0.25s;
  transform: ${({ expanded }) => (expanded ? "none" : "scale(-1)")};
  transition: 0.375s;
  margin-top: 0.25rem;
  path {
    transition: 0.25s;
    stroke: var(--teva-orange);
  }
  @media (min-width: 768px) {
    width: 0.75rem;
    height: auto;
    min-width: 0.75rem;
    path {
      stroke: var(--teva-orange);
    }
  }
`;

const Content = styled(motion.section)`
  overflow: hidden;
  a {
    outline: none;
    border: none;
    line-height: 1.25em;
    font-size: 1.15rem;
    font-weight: 400;
    width: 100%;
    text-align: center;
    background: white;
    color: var(--primary-color);
    font-family: Helvetica, sans-serif;
    letter-spacing: 0.5px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5em auto;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &:first-child {
      margin-top: 2rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
    svg {
      min-width: 1.5rem;
      width: 1.5rem;
      margin-right: 0.75rem;
      height: 1.5rem;
    }
  }
  @media (min-width: 768px) {
    a {
      justify-content: flex-start;
      font-size: 1rem;
      margin: 0;
      margin-bottom: 1.5em;
      &:first-child {
        margin-top: 0;
      }
    }
    svg {
      min-width: 1.5rem;
      width: 1.5rem;
      margin-right: 1.5rem;
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  @media (min-width: 768px) {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0rem;
    height: auto;
    width: 300px;
    background: white;
    padding: 2rem 1.5rem;
    border-radius: 0.5rem;
    text-align: left;
    box-shadow: 5px 5px 15px rgba(0, 90, 133, 0.2);
    display: ${({ expanded }) => (expanded ? "flex" : "none")};
  }
  @media (min-width: 1150px) {
    left: 2rem;
  }
`;

const Heading = styled(motion.div)`
  font-weight: 700;
  font-size: 2rem;
  line-height: 1em;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  margin: 0;
  text-align: center;
  div {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 768px) {
    font-size: 1.1rem;
    padding-left: 3rem;
    height: 2rem;
    width: auto;
    color: var(--primary-color);
    justify-content: center;
    margin: 0;
  }
`;

const Container = styled.div`
  width: auto;
  height: auto;
  position: relative;
  height: auto;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @media (min-width: 768px) {
    margin-left: 1rem;
    height: 2rem;
    position: relative;
    overflow: visible;
  }
`;

export default Dropdown;
