import * as React from "react";

function SvgInstructions(props) {
  return (
    <svg
      width="88"
      height="83"
      viewBox="0 0 88 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M80.3401 42.081H65.7652L51.1903 56.6559L48.0931 42.081H38.4372L36.6154 36.6154L31.1498 34.7935V7.46559C31.1498 4.36842 33.5182 2 36.6154 2H80.3401C83.4372 2 85.8057 4.36842 85.8057 7.46559V27.5061V36.6154C85.8057 39.7126 83.4372 42.081 80.3401 42.081Z"
        fill="#97D326"
        fill-opacity="0.3"
      />
      <path
        d="M7.46558 71.2307V27.506C7.46558 25.502 9.10525 23.8623 11.1093 23.8623H27.5061"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M80.3401 45.7246V71.2307"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M31.1498 31.1498V7.46559C31.1498 4.36842 33.5182 2 36.6154 2H80.3401C83.4372 2 85.8057 4.36842 85.8057 7.46559V36.6154C85.8057 39.7126 83.4372 42.081 80.3401 42.081H65.7652L52.4656 55.3806C51.3725 56.4737 49.3684 55.7449 49.3684 54.1053V42.081H42.081"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M45.7248 67.5869V64.672C45.7248 59.5707 35.1579 56.6558 31.1499 56.6558C27.1418 56.6558 16.575 59.5707 16.575 64.672V67.5869"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M31.1498 34.7935C27.1417 34.7935 23.8623 38.0728 23.8623 42.0809V43.9028C23.8623 47.9109 27.1417 51.1902 31.1498 51.1902C35.1579 51.1902 38.4372 47.9109 38.4372 43.9028V42.0809C38.4372 38.0728 35.1579 34.7935 31.1498 34.7935Z"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M85.8057 71.2307H53.0121L49.3684 74.8744H40.2591L36.6154 71.2307H2V73.0526C2 77.0607 5.27935 80.34 9.28745 80.34H78.5182C82.5263 80.34 85.8057 77.0607 85.8057 73.0526V71.2307Z"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M67.587 22.0405L54.1052 30.421V13.6599L67.587 22.0405Z"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
    </svg>
  );
}

export default SvgInstructions;
