import * as React from "react";

function SvgGalaxy(props) {
  return (
    <svg
      viewBox="0 0 259 403"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.842 154.188c-7.092-4.554-9.063-14.059-4.531-21.188 4.531-7.129 13.988-9.109 21.08-4.554 7.093 4.554 9.064 14.059 4.532 21.188-4.531 7.128-13.988 9.109-21.08 4.554z"
        fill="#FFC800"
      />
      <path
        d="M33.099 132.208c-.197.396-.591.594-.788.792-4.532 7.129-2.561 16.634 4.531 21.188 7.093 4.555 16.55 2.574 21.081-4.554-13.2 0-21.475-7.723-24.824-17.426z"
        fill="#FFEE78"
      />
      <path
        d="M12.61 141.317c0 3.564-2.76 6.336-6.305 6.336-3.547 0-6.305-2.772-6.305-6.336 0-3.564 2.758-6.337 6.305-6.337 3.546 0 6.304 2.773 6.304 6.337z"
        fill="#6A449D"
      />
      <path
        d="M47.875 174.782c0 3.565-2.758 6.337-6.304 6.337-3.547 0-6.305-2.772-6.305-6.337 0-3.564 2.758-6.336 6.305-6.336 3.546 0 6.304 2.772 6.304 6.336z"
        fill="#FF620D"
      />
      <path
        d="M124.515 95.376s.394 3.96 1.97 5.545c1.379 1.386 5.122 1.584 5.122 1.584s-3.546.198-5.122 1.584c-1.773 1.386-1.97 5.545-1.97 5.545s-.197-3.763-1.773-5.545c-1.182-1.188-5.32-1.584-5.32-1.584s4.138-.396 5.32-1.584c1.576-1.782 1.773-5.545 1.773-5.545zM79.989 114.386s.394 3.169 1.576 4.555c1.182 1.188 4.137 1.188 4.137 1.188s-2.758.198-4.137 1.188c-1.38 1.188-1.576 4.554-1.576 4.554s-.197-3.168-1.38-4.554c-.984-.99-4.334-1.188-4.334-1.188s3.35-.198 4.335-1.386c1.182-1.188 1.379-4.357 1.379-4.357zM17.732 114.386s.394 3.169 1.576 4.555c1.182 1.188 4.137 1.188 4.137 1.188s-2.758.198-4.137 1.188c-1.38 1.188-1.576 4.554-1.576 4.554s-.197-3.168-1.38-4.554c-.985-.99-4.334-1.188-4.334-1.188s3.35-.198 4.334-1.386c1.183-1.188 1.38-4.357 1.38-4.357zM51.027 93s.591 5.347 2.562 7.327c1.773 1.782 6.698 2.178 6.698 2.178s-4.531.198-6.698 2.178c-2.365 1.782-2.562 7.327-2.562 7.327s-.197-5.149-2.364-7.327c-1.576-1.782-7.092-2.178-7.092-2.178s5.516-.396 7.092-2.178C50.83 98.149 51.027 93 51.027 93zM136.533 150.03s.197 2.574 1.379 3.762c.985.99 3.349.99 3.349.99s-2.167 0-3.349.99c-1.182.99-1.379 3.763-1.379 3.763s-.197-2.575-1.182-3.763c-.788-.792-3.546-.99-3.546-.99s2.758-.198 3.546-.99c1.182-1.188 1.182-3.762 1.182-3.762zM83.338 148.644s.197 1.386.788 1.98c.394.396 1.774.594 1.774.594s-1.183 0-1.774.594c-.59.396-.788 1.98-.788 1.98s0-1.386-.59-1.98c-.395-.396-1.774-.594-1.774-.594s1.38-.198 1.773-.594c.591-.594.591-1.98.591-1.98z"
        fill="#fff"
      />
      <path
        d="M122.151 144.485c0 6.535-5.32 11.881-11.821 11.881-6.502 0-11.822-5.346-11.822-11.881 0-6.534 5.32-11.881 11.822-11.881 6.501 0 11.821 5.347 11.821 11.881z"
        fill="#FF620D"
      />
      <path
        d="M74.276 166.267c0 3.367-2.759 6.337-6.305 6.337-3.35 0-6.305-2.772-6.305-6.337 0-3.366 2.759-6.336 6.305-6.336 3.546.198 6.305 2.97 6.305 6.336z"
        fill="#6A449D"
      />
      <path
        d="M166.448 30.673c0 .792-.591 1.584-1.379 1.782-.788 0-1.576-.594-1.773-1.386 0-.792.591-1.584 1.379-1.782.985-.198 1.773.396 1.773 1.386zM189.499 1.762c0 .793-.591 1.585-1.379 1.783-.788 0-1.576-.594-1.773-1.386 0-.793.591-1.585 1.379-1.783.985-.198 1.773.396 1.773 1.386zM159.122 61s.592 3.168 1.971 4.356c1.182.99 4.334.793 4.334.793s-2.758.396-3.94 1.584c-1.379 1.188-1.182 4.752-1.182 4.752s-.394-3.168-1.774-4.356c-.985-.99-4.531-.792-4.531-.792s3.349-.594 4.137-1.782c1.182-1.585.985-4.555.985-4.555zM119.122 14s.591 3.168 1.971 4.356c1.182.99 4.334.792 4.334.792s-2.758.397-3.94 1.585c-1.379 1.188-1.182 4.752-1.182 4.752s-.394-3.168-1.774-4.356c-.985-.99-4.531-.792-4.531-.792s3.349-.594 4.137-1.782c1.182-1.387.985-4.555.985-4.555zM82.078 54s.985 4.95 3.152 6.733c1.97 1.584 6.699 1.386 6.699 1.386s-4.335.594-6.305 2.574c-1.97 1.98-1.773 7.327-1.773 7.327s-.788-4.95-2.955-6.733C79.123 63.703 74 63.901 74 63.901s5.123-.99 6.502-2.772C82.472 58.752 82.078 54 82.078 54zM252.122 391s.592 3.168 1.971 4.356c1.182.991 4.334.793 4.334.793s-2.758.396-3.94 1.584c-1.379 1.188-1.182 4.752-1.182 4.752s-.394-3.168-1.774-4.356c-.985-.99-4.531-.792-4.531-.792s3.349-.594 4.137-1.783c1.182-1.584.985-4.554.985-4.554zM212.122 344s.591 3.168 1.971 4.356c1.182.991 4.334.793 4.334.793s-2.758.396-3.94 1.584c-1.379 1.188-1.182 4.752-1.182 4.752s-.394-3.168-1.774-4.356c-.985-.99-4.531-.792-4.531-.792s3.349-.594 4.137-1.783c1.182-1.386.985-4.554.985-4.554zM175.078 384s.985 4.95 3.152 6.733c1.97 1.584 6.699 1.386 6.699 1.386s-4.335.594-6.305 2.574c-1.97 1.98-1.773 7.327-1.773 7.327s-.788-4.951-2.955-6.733c-1.774-1.584-6.896-1.386-6.896-1.386s5.122-.99 6.502-2.772c1.97-2.377 1.576-7.129 1.576-7.129z"
        fill="#fff"
      />
      <path
        d="M142 49.972c0 7.386-4.736 13.701-11.333 16.013a17 17 0 01-5.695.959C115.612 67 108 59.388 108 49.972 108 40.612 115.612 33 124.972 33c2.086 0 4.06.395 5.864 1.071 6.54 2.369 11.164 8.627 11.164 15.9z"
        fill="#EFE0ED"
      />
      <path
        d="M142 49.972c0 7.386-4.736 13.701-11.333 16.013h-2.256a2.262 2.262 0 01-2.255-2.255v-.057a2.262 2.262 0 012.255-2.255h3.947a2.262 2.262 0 002.256-2.255v-.057a2.262 2.262 0 00-2.256-2.255h-12.179a2.262 2.262 0 01-2.255-2.256v-.056a2.262 2.262 0 012.255-2.255h18.269a2.262 2.262 0 002.255-2.256v-.056a2.262 2.262 0 00-2.255-2.256h-12.067a2.262 2.262 0 01-2.255-2.255v-.056a2.262 2.262 0 012.255-2.256h5.977a2.262 2.262 0 002.256-2.255 2.262 2.262 0 00-2.256-2.255h-9.698a2.262 2.262 0 01-2.255-2.256v-.056a2.262 2.262 0 012.255-2.256h8.176c6.54 2.368 11.164 8.627 11.164 15.9z"
        fill="#D6BFD3"
      />
      <path
        d="M121.871 41.683a2.647 2.647 0 01-2.65 2.65 2.647 2.647 0 01-2.65-2.65 2.647 2.647 0 012.65-2.65 2.647 2.647 0 012.65 2.65z"
        fill="#D6BFD3"
      />
      <path
        opacity={0.58}
        d="M134.311 54.834a4.68 4.68 0 10-9.24-1.496 4.68 4.68 0 009.24 1.496z"
        fill="#B79CB4"
      />
      <path
        d="M120.123 64.18a2.65 2.65 0 100-5.3 2.65 2.65 0 000 5.3zM117.134 50.028a4.042 4.042 0 01-4.059 4.06 4.043 4.043 0 01-4.06-4.06 4.043 4.043 0 014.06-4.06 4.042 4.042 0 014.059 4.06z"
        fill="#D6BFD3"
      />
    </svg>
  );
}

export default SvgGalaxy;
