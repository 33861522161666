import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Container, Grid, Button } from "styles";
import {
  KidsSmallMask,
  KidsMediumMask,
  YouthMouthpiece,
  AdultSmallMask,
  AdultLargeMask,
  AdultMouthpiece,
} from "components";
import { motion } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

const ProductCarousel = () => {
  const [[page], setPage] = useState([0, 0]);

  const paginate = useCallback(
    (dir) => {
      setPage([page + dir]);
    },
    [page]
  );

  useEffect(() => {
    const timer = setTimeout(() => paginate(1), 10000);
    return () => clearTimeout(timer);
  }, [paginate]);

  const index = wrap(0, 6, page);

  const data = useStaticQuery(graphql`
    query {
      kidsSmallMaskImage: file(
        relativePath: { eq: "aerochamber-kids-small-mask.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      kidsMediumMaskImage: file(
        relativePath: { eq: "aerochamber-kids-medium-mask.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      youthMouthpieceImage: file(
        relativePath: { eq: "aerochamber-youth-mouthpiece.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      adultMouthpieceImage: file(
        relativePath: { eq: "aerochamber-adult-mouthpiece.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      adultSmallMaskImage: file(
        relativePath: { eq: "aerochamber-adult-small-mask.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      adultLargeMaskImage: file(
        relativePath: { eq: "aerochamber-adult-large-mask.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  return (
    <Container>
      <ProductGrid>
        <Caption>
          <Title>
            AeroChamber Plus<sup>®</sup> Flow-Vu<sup>®</sup>:
            <strong> Seeing Is The Difference</strong>
          </Title>
          <Description>
            AeroChamber Plus<sup>®</sup> Flow-Vu<sup>®</sup> is an anti-static
            valved holding chamber designed with patients in mind. Patients can
            use an AeroChamber Plus<sup>®</sup> Flow-Vu<sup>®</sup> to help
            improve medication delivery from a metered dose inhaler (MDI).
          </Description>
          <Link
            to="/products/"
            style={{ marginTop: "2rem", marginBottom: "0" }}
          >
            <StyledButton type="button">See Products</StyledButton>
          </Link>
        </Caption>
        <ProductsContainer>
          <SelectedProduct>
            {index === 0 ? (
              <KidsSmallMask image={data.kidsSmallMaskImage} />
            ) : index === 1 ? (
              <KidsMediumMask image={data.kidsMediumMaskImage} />
            ) : index === 2 ? (
              <YouthMouthpiece image={data.youthMouthpieceImage} />
            ) : index === 3 ? (
              <AdultMouthpiece image={data.adultMouthpieceImage} />
            ) : index === 4 ? (
              <AdultSmallMask image={data.adultSmallMaskImage} />
            ) : (
              <AdultLargeMask image={data.adultLargeMaskImage} />
            )}
          </SelectedProduct>
          <ProductSelection>
            <SelectProduct
              onClick={() => {
                setPage([0, 1]);
              }}
            >
              <ImageThumb>
                <GatsbyImage
                  image={getImage(data.kidsSmallMaskImage)}
                  alt="AeroChamber Kids Small Mask"
                  className="product-image"
                />
              </ImageThumb>
            </SelectProduct>
            <SelectProduct
              onClick={() => {
                setPage([1, 1]);
              }}
            >
              <ImageThumb>
                <GatsbyImage
                  image={getImage(data.kidsMediumMaskImage)}
                  alt="AeroChamber Kids Medium Mask"
                  className="product-image"
                />
              </ImageThumb>
            </SelectProduct>
            <SelectProduct
              onClick={() => {
                setPage([2, 1]);
              }}
            >
              <ImageThumb>
                <GatsbyImage
                  image={getImage(data.youthMouthpieceImage)}
                  alt="AeroChamber Youth Mouthpiece"
                  className="product-image"
                />
              </ImageThumb>
            </SelectProduct>
            <SelectProduct
              onClick={() => {
                setPage([3, 1]);
              }}
            >
              <ImageThumb>
                <GatsbyImage
                  image={getImage(data.adultMouthpieceImage)}
                  alt="AeroChamber Adult Mouthpiece"
                  className="product-image"
                />
              </ImageThumb>
            </SelectProduct>
            <SelectProduct
              onClick={() => {
                setPage([4, 1]);
              }}
            >
              <ImageThumb>
                <GatsbyImage
                  image={getImage(data.adultSmallMaskImage)}
                  alt="AeroChamber Adult Small Mask"
                  className="product-image"
                />
              </ImageThumb>
            </SelectProduct>
            <SelectProduct
              onClick={() => {
                setPage([5, 1]);
              }}
            >
              <ImageThumb>
                <GatsbyImage
                  image={getImage(data.adultLargeMaskImage)}
                  alt="AeroChamber Adult Large Mask"
                  className="product-image"
                />
              </ImageThumb>
            </SelectProduct>
          </ProductSelection>
        </ProductsContainer>
      </ProductGrid>
    </Container>
  );
};

const StyledButton = styled(Button)`
  transition: 0.5s;
  background: #007ac0;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: var(--primary-color);
    color: white;
  }
`;

const ImageThumb = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-47.5%, -60%);
  width: 150%;
  max-width: 150%;
  height: 150%;
  .product-image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
    .gatsby-image-wrapper {
      max-width: 100%;

      img {
        object-fit: contain;
        max-width: 100%;
        width: 100%;
      }
    }
  }
`;

const SelectProduct = styled.button`
  border: none;
  outline: none;
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 100%;
  border-radius: 50%;
  background: rgba(47, 128, 237, 0.1);
  overflow: visible;
  transition: 0.375s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &:nth-child(1) {
    &:hover {
      transform: rotate(-45deg) scale(1.2);
      background: var(--teva-orange);
    }
  }
  &:nth-child(2) {
    &:hover {
      transform: rotate(-45deg) scale(1.2);
      background: var(--teva-yellow);
    }
  }
  &:nth-child(3) {
    &:hover {
      transform: rotate(-45deg) scale(1.2);
      background: var(--teva-green);
    }
  }
  &:nth-child(4) {
    &:hover {
      transform: rotate(-45deg) scale(1.2);
      background: var(--teva-blue);
    }
  }
  &:nth-child(5) {
    &:hover {
      transform: rotate(-45deg) scale(1.2);
      background: var(--teva-purple);
    }
  }
  &:nth-child(6) {
    &:hover {
      transform: rotate(-45deg) scale(1.2);
      background: var(--teva-blue);
    }
  }
`;

const ProductSelection = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  @media (min-width: 768px) {
    grid-gap: 1.25rem;
  }
  @media (min-width: 1150px) {
    grid-gap: 1.25rem;
  }
`;

const SelectedProduct = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 100%;
  margin-bottom: 6rem;
`;

const Description = styled.p`
  font-size: 1.15rem;
  font-weight: 300;
  line-height: 1.3em;
  color: var(--primary-color);
  sup {
    font-size: 0.5em;
  }
  @media (min-width: 768px) {
    font-size: 1.15rem;
    line-height: 1.5em;
  }
  @media (min-width: 1150px) {
    font-size: 1.25rem;
    line-height: 1.5em;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  line-height: 1.3em;
  font-weight: 300;
  color: var(--primary-color);
  margin-top: 0;
  margin-bottom: 0.5em;
  strong {
    font-weight: 700;
  }
  sup {
    font-size: 0.5em;
  }
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
  @media (min-width: 1150px) {
    font-size: 4rem;
  }
`;

const Caption = styled.div`
  position: relative;
  z-index: 2;
  grid-area: caption;
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: 1/7;
  @media (min-width: 768px) {
    grid-column: 1/7;
  }
  @media (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const ProductsContainer = styled.div`
  grid-area: product;
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: 1/7;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    grid-column: 7/13;
  }
  @media (min-width: 1150px) {
    grid-column: 8/12;
  }
`;

const ProductGrid = styled(Grid)`
  grid-template-areas: "product" "caption";
  grid-row-gap: 2rem;
  overflow: hidden;
  @media (min-width: 768px) {
    align-items: center;
    grid-template-areas: "caption product";
  }
`;

export default ProductCarousel;
