import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { ExitRamp } from "components";
import {
  EmailIcon,
  MobIcon,
  LocationIcon,
  WebIcon,
  TevaLogoInverted,
  // FacebookIcon,
  // TwitterIcon,
  // LinkedinIcon,
  FooterLogo,
} from "images/svgs";
import { Grid, Button, P } from "styles";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Grid style={{ margin: "0 auto", marginBottom: "3rem" }}>
          <GridItem>
            <h4>Sitemap</h4>
            <Link to="/">Home</Link>
            <Link to="/products/">Products</Link>
            <Link to="/resources/healthcare-professionals">HCP</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/clinical-info">Clinical Info</Link>
            <Link to="/resources/mask-sizing">Mask Sizing</Link>
          </GridItem>
          <GridItem>
            <h4>Products</h4>
            <Link to="/products" state={{ product: 0 }}>
              Small Mask
            </Link>
            <Link to="/products" state={{ product: 1 }}>
              Medium Mask
            </Link>
            <Link to="/products" state={{ product: 2 }}>
              Youth Mouthpiece
            </Link>
            <Link to="/products" state={{ product: 3 }}>
              Adult Mouthpiece
            </Link>
            <Link to="/products" state={{ product: 4 }}>
              Adult Small Mask
            </Link>
            <Link to="/products" state={{ product: 5 }}>
              Adult Large Mask
            </Link>
          </GridItem>
          <GridItem>
            <h4>Contact Us</h4>
            <a href="mailto: info@teva.ie">
              <EmailIcon /> info@teva.ie
            </a>
            <a href="tel: 1800 201 700">
              <MobIcon /> 1800 201 700
            </a>
            {/*   <a
              href="https://goo.gl/maps/zNc1YthVcfuEPhK7A"
              target="_blank"
              rel="noreferrer"
            >
              <LocationIcon /> Digital Office Centre Swords, Suite 101 - 103,
              Balheary Demense, Balheary Road, Swords, Co Dublin, K67E5AO,
              Ireland
            </a> */}
            <ExitRamp
              trigger={
                <Button
                  type="button"
                  aria-label="Contact Us | Teva Ireland"
                  style={{ marginLeft: "0" }}
                >
                  <WebIcon />
                  <strong>Teva Ireland</strong>
                </Button>
              }
              modalLink={`https://www.teva.ie/contact-us/`}
            />
            <br />
            <P>
              Registered in Ireland No. 366280.
              <br /> Registered office: Teva Pharmaceuticals Ireland, Unit 301,
              Waterford Industrial Park, Co Waterford, X91 WK68 Republic of
              Ireland.
            </P>
            <br />
          </GridItem>
          <LogoContainer>
            <FooterLogo />
          </LogoContainer>
          <AEReporting>
            <P>
              Adverse events should be reported. Reporting forms and information
              can be found at
              <ExitRamp
                trigger={
                  <Button type="button" aria-label="HPRA Website">
                    <strong>www.hpra.ie</strong>
                  </Button>
                }
                modalLink={`https://www.hpra.ie/`}
              />
              Adverse events should also be reported to{" "}
              <strong>Teva UK Limited</strong> on{" "}
              <strong>+44 207 540 7117</strong> or email{" "}
              <strong>medinfo@tevauk.com</strong>
            </P>
          </AEReporting>

          {/* <Socials>
          <Social href="https://www.facebook.com/" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </Social>
          <Social href="https://twitter.com/" target="_blank" rel="noreferrer">
            <TwitterIcon />
          </Social>
          <Social href="https://www.linkedin.com/company/" target="_blank" rel="noreferrer">
            <LinkedinIcon />
          </Social>
        </Socials> */}
        </Grid>
      </Container>
      <Container>
        <Copyright>
          <Grid>
            <CopyrightText>
              The AeroChamber videos on this site have been created by Trudell&nbsp;and reviewed by Teva.
              <br />© Copyright @ 2023 Teva Pharmaceuticals Ireland. All rights
              reserved.
              <br />
              Approval code: M: AER-IE-00001 (2.0) / T: AER-IE-00018 (V1.0) M: AER-IE-00019 (V1.0) | Date of preparation: July 2023
            </CopyrightText>
            <CopyrightLinks>
              <ExitRamp
                trigger={
                  <Button type="button" aria-label="Accessibility">
                    Accessibility
                  </Button>
                }
                modalLink={`https://www.teva.ie/general-pages/accessibility/`}
              />
              <ExitRamp
                trigger={
                  <Button type="button" aria-label="Terms">
                    Terms
                  </Button>
                }
                modalLink={`https://www.teva.ie/general-pages/terms-of-use/`}
              />
              <ExitRamp
                trigger={
                  <Button type="button" aria-label="Privacy Policy">
                    Privacy Policy
                  </Button>
                }
                modalLink={`https://www.teva.ie/general-pages/privacy-and-cookie-notice/`}
              />
              <a class="optanon-toggle-display" href="#">
                <Button type="button" aria-label="Reporting AE" style={{ cursor: 'pointer' }}>
                  Cookie Settings
                </Button>
              </a>
              <ExitRamp
                trigger={
                  <Button type="button" aria-label="Privacy Policy">
                    Accessibility
                  </Button>
                }
                modalLink={`https://www.teva.ie/general-pages/accessibility/`}
              />
              <ExitRamp
                trigger={
                  <Button type="button" aria-label="PHV Privacy Policy">
                    PHV Privacy Policy
                  </Button>
                }
                modalLink={`https://www.teva.ie/general-pages/phv_privacy_policy/`}
              />
              <ExitRamp
                trigger={
                  <Button type="button" aria-label="Reporting AE">
                    Reporting AE
                  </Button>
                }
                modalLink={`https://products.tevauk.com/Reporting-side-effects`}
              />
            </CopyrightLinks>
          </Grid>
        </Copyright>
        <TevaLogo>
          <TevaLogoInverted />
        </TevaLogo>
      </Container>
    </footer>
  );
};

const CopyrightLinks = styled.div`
  grid-column: 1/7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-right: 0.325rem;
  @media (min-width: 768px) {
    grid-column: 2/12;
    color: #00507d;
    padding-right: 0;
  }
  ${Button} {
    padding: 0.5rem !important;
    text-decoration: underline;
    text-transform: capitalize;
  }
`;

const CopyrightText = styled.div`
  grid-column: 1/7;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.4em;
  text-align: center;
  margin: 0 1rem;
  @media (min-width: 768px) {
    margin: 0;
    grid-column: 2/12;
    color: #00507d;
  }
`;

const Copyright = styled.div`
  width: 100%;
  background: rgba(47, 128, 237, 0.1);
  padding: 2rem 1rem;
  color: #00507d;
  padding-bottom: 6rem;
  ${Grid} {
    margin: 0 auto;
    padding: 0;
  }
  @media (min-width: 768px) {
    padding: 2rem 1rem;
  }
  @media (min-width: 1150px) {
    padding: 1rem;
  }
`;

const TevaLogo = styled.div`
  height: 250px;
  width: auto;
  position: absolute;
  right: 0;
  z-index: 5;
  bottom: 0;
  svg {
    height: 100%;
    width: auto;
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/4;
  &:nth-child(even) {
    grid-column: 4/7;
  }
  h4 {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0.75em;
    text-transform: uppercase;
    line-height: 1.5em;
  }
  a {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.4em;
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #00507d;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    strong {
      margin: 0 0.25rem;
      font-size: 0.925em;
    }
    svg {
      height: auto;
      min-width: 0.925rem;
      width: 0.925rem;
      max-height: 0.925rem;
      margin-right: 0.925rem;
      margin-top: 0.25rem;
      path {
        stroke: var(--primary-color);
      }
    }
    @media (min-width: 1150px) {
      font-size: 0.875rem;
    }
  }
  @media (min-width: 768px) {
    grid-column: span 2;
    &:first-child {
      grid-column: 2 / span 2;
    }
    &:nth-child(even) {
      grid-column: span 2;
    }
    &:nth-child(3) {
      grid-column: 9/12;
    }
  }
`;

const LogoContainer = styled(GridItem)`
  grid-column: 1/4 !important;
  margin-top: 3rem;
  @media (min-width: 768px) {
    grid-column: 2/5 !important;
  }
  @media (min-width: 1150px) {
    grid-column: 2/4 !important;
  }
`;

const AEReporting = styled(GridItem)`
  margin: 1rem 0;
  grid-column: 1/7 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  ${P} {
    font-size: 0.875rem;
    line-height: 1.3em;
    color: #00507d;
    margin: 0;
  }
  padding: 1em;
  border: 1px solid #00507d;
  @media (min-width: 768px) {
    grid-column: 5/12 !important;
    margin-left: 2rem;
  }
  @media (min-width: 1150px) {
    grid-column: 4/9 !important;
  }
`;

const Container = styled.section`
  width: 100%;
  position: relative;
  background: var(--secondary-color);
  color: #00507d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  ${P} {
    font-size: 0.875rem;
    line-height: 1.3em;
    color: #00507d;
    margin: 0;
  }
  ${Button} {
    display: flex;
    align-items: Center;
    justify-content: center;
    margin: 0 0.25rem;
    padding: 0.5rem 0.75rem;
    text-transform: none;
    font-weight: 300;
    font-size: 0.875rem;
    background: none;
    color: #00507d;
    white-space: nowrap;
    padding: 0;
    svg {
      padding: 0;
      height: auto;
      min-width: 0.925rem;
      width: 0.925rem;
      max-height: 0.925rem;
      margin-right: 0.925rem;
      margin-top: 0.25rem;
    }
  }
`;

export default Footer;
