import React from "react";
import styled from "styled-components";
import { H2 } from "styles";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  ProductBackground,
  Clouds,
  BalloonTeddy,
  Plane,
  Balloon,
} from "images/svgs";

const KidsMediumMask = ({ image }) => {
  return (
      <Product>
        <PrimaryBackground
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75 }}
        >
          <ProductBackground />
        </PrimaryBackground>
        <SecondaryBackground
          initial={{ opacity: 0, scale: 0.75, y: "-50%", x: "-50%" }}
          animate={{ opacity: 1, scale: 1, y: "-50%", x: "-50%" }}
          transition={{ duration: 0.75 }}
        >
          <Clouds />
        </SecondaryBackground>
        <HotAirBalloon
          initial={{ opacity: 0, scale: 0.75, y: "-30%", x: "-50%" }}
          animate={{ opacity: 1, scale: 1, y: "-50%", x: "-50%" }}
          transition={{ duration: 1.25, delay: 0.25 }}
        >
          <Balloon />
        </HotAirBalloon>
        <Airplane
          initial={{
            opacity: 0,
            scale: 0.75,
            rotate: -35,
            y: "-30%",
            x: "-60%",
          }}
          animate={{ opacity: 1, scale: 1, rotate: 0, y: "-50%", x: "-50%" }}
          transition={{ duration: 1.25 }}
        >
          <Plane />
        </Airplane>
        <Teddy
          initial={{ opacity: 0, scale: 0.75, y: "-30%", x: "-60%" }}
          animate={{ opacity: 1, scale: 1, y: "-60%", x: "-60%" }}
          transition={{ duration: 1.25 }}
        >
          <BalloonTeddy />
        </Teddy>

        <MainImage
          initial={{ opacity: 0, rotate: 180, y: "-50%", x: "-50%" }}
          animate={{ opacity: 1, rotate: 225, y: "-50%", x: "-50%" }}
          transition={{ duration: 0.75 }}
        >
          <GatsbyImage
            image={getImage(image)}
            alt="AeroChamber Kids Medium Mask"
            className="product-image"
          />
        </MainImage>
        <Caption
          initial={{ transform: "rotateX(90deg) perspective(300px)" }}
          animate={{ transform: "rotateX(0deg) perspective(0px)" }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <H2>
            Medium Mask,
            <br />
            <span>1-5 years</span>
          </H2>
        </Caption>
      </Product>
  );
};

const Caption = styled(motion.div)`
  position: absolute;
  top: 85%;
  right: -10%;
  z-index: 3;
  width: auto;
  max-width: 220px;
  ${H2} {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.25em;
    margin-bottom: 0;
    width: auto;
    span {
      font-size: 0.75em;
    }
  }
  @media (min-width: 1150px) {
    top: 80%;
    ${H2} {
      font-size: 2rem;
    }
  }
`;

const Teddy = styled(motion.div)`
  width: 4rem;
  height: auto;
  position: absolute;
  top: 65%;
  left: 85%;
  transform: translate(-50%, -50%);
  z-index: 3;
  @media (min-width: 768px) {
    width: 6rem;
  }
`;

const Airplane = styled(motion.div)`
  width: 2rem;
  height: auto;
  position: absolute;
  top: 90%;
  left: 45%;
  transform: translate(-50%, -50%);
  z-index: 3;
  @media (min-width: 768px) {
    width: 4rem;
  }
`;

const HotAirBalloon = styled(motion.div)`
  width: 3.5rem;
  height: auto;
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 3;
  @media (min-width: 768px) {
    width: 4.5rem;
  }
`;

const SecondaryBackground = styled(motion.div)`
  width: 70%;
  height: auto;
  position: absolute;
  top: 48%;
  left: 38%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const PrimaryBackground = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 1;
  svg {
    width: 500px;
    max-width: 100%;
    height: 100%;
  }
  width: 100%;
  @media (min-width: 1150px) {
    width: 120%;
  }
`;

const MainImage = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 100%;
  padding-bottom: 100%;
  .gatsby-image-wrapper {
    width: 130% !important;
    height: 130% !important;
    overflow: visible;
    position: absolute;
    top: -25%;
    left: -10%;
    max-width: 130%;
    max-height: 130%;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  max-width: 100%;
  @media (min-width: 1150px) {
    max-width: 130%;
  }
`;

const Product = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  @media (min-width: 768px) {
    width: 90%;
    height: 90%;
  }
  @media (min-width: 1150px) {
    width: 100%;
    height: 100%;
  }
`;

export default KidsMediumMask;
