import React from "react";
import styled from "styled-components";

const ModalTrigger = ({ buttonRef, trigger, showModal }) => {
  return (
    <ModalButton ref={buttonRef} onClick={showModal}>
      {trigger}
    </ModalButton>
  );
};

const ModalButton = styled.div`
  display: inline-block;
  background: transparent;
  cursor: pointer;
  button {
    cursor: pointer;
  }
`;

export default ModalTrigger;
