import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Button } from "styles";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleChange = (e) => {
    const changedElement = e.target;
    const { name, value } = changedElement;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formValues
      }),
    })
      .then(() => setFormSubmitted(true))
      .catch((error) => alert(error));
  };

  return (
    <>
      {!formSubmitted && (
        <Form
          id="contact"
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="bot-field" />
          <label htmlFor="name">Your Name*:</label>
          <input type="text" name="name" onChange={handleChange} required />
          <label htmlFor="email">E-mail Address*:</label>
          <input type="email" name="email" onChange={handleChange} required />
          <label htmlFor="email">Phone Number:</label>
          <input type="tel" name="phone" onChange={handleChange} />
          <label htmlFor="subject">Subject:</label>
          <input type="text" name="subject" onChange={handleChange} required />
          <label htmlFor="message">Message:</label>
          <textarea
            name="message"
            placeholder="Type your message here..."
            onChange={handleChange}
            required
          ></textarea>
          <Terms>
            <input
              type="checkbox"
              id="terms_and_conditions"
              checked={termsAccepted}
              value="1"
              onChange={() => {
                setTermsAccepted(!termsAccepted);
              }}
            />
            I agree to having the above details stored for future
            communications.
          </Terms>
          <StyledButton contrast type="submit" disabled={!termsAccepted}>
            Send
          </StyledButton>
        </Form>
      )}
      {formSubmitted && (
        <ThankYou
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p>
            Thank you! We have received your message and will endeavour to
            respond as soon as possible.
          </p>
        </ThankYou>
      )}
    </>
  );
};

const Terms = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--primary-color);
  font-size: 0.875rem;
  line-height: 1.3em;
  input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    margin-top: 2px;
    margin-right: 1rem;
  }
  p {
    color: var(--muted);
    font-size: 0.75rem !important;
    font-family: "Poppins Light" !important;
  }
`;

const StyledButton = styled(Button)`
  width: 8.75rem;
  height: 3rem;
  align-self: flex-end;
  transition: 0.5s;
  margin: 0;
  margin-top:2rem;
  background: var(--teva-blue);
  margin-bottom: 2rem;
  cursor: pointer;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  &:hover {
    background: var(--primary-color);

    color: white;
  }
  &:disabled {
    background: var(--primary-color);
    opacity: 0.8;
    cursor: not-allowed;
    color: white;

    opacity: 0.75;
  }
`;

const ThankYou = styled(motion.div)`
  grid-area: form;
  background: #eaf6ff;
  max-height: 150px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.425rem;
  margin-top: 1.5rem;
  p {
    margin: 2rem 0;
    text-align: center;
  }

  @media (min-width: 1150px) {
    grid-column: 2/6;
    padding: 2rem;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 2rem 1rem;
  background: rgba(0, 127, 200, 0.1);
  input {
    height: 2.5rem;
    width: 100%;
    background: white;
    border: 1px solid var(--teva-blue);
    color: var(--primary-color);
    outline: none;
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    margin-bottom: 2rem;
    border-radius: 2px;
    &:hover {
      border: 1px solid var(--teva-blue);
    }
    &:focus {
      border: 1px solid var(--teva-blue);
    }
  }
  label {
    font-size: 1rem;
    color: var(--primary-color);
    font-family: "Helvetica Neue";
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  textarea {
    height: 150px;
    max-height: 100%;
    width: 100%;
    border: 1px solid var(--teva-blue);
    outline: none;
    background: white;
    color: var(--primary-color);
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
    &::placeholder {
      color: var(--primary-color);
      font-size: 0.875rem;
      font-family: "Helvetica Neue";
    }
    &:hover {
      border: 1px solid var(--teva-blue);
      outline: none;
    }
    &:focus {
      border: 1px solid var(--teva-blue);
    }
  }
  @media (min-width: 768px) {
    padding: 2rem 1.5rem;
    textarea {
      height: 150px;
    }
  }
  @media (min-width: 1150px) {
    padding:3rem;
    textarea {
      height: 150px;
    }
  }
`;

export default ContactForm;
