import React, { useRef, useEffect } from "react";
import HCPRampContent from "../HCPRampContent";

const HCPRamp = ({  setPopup, link1, link2 }) => {
  const closeButton = useRef();
  const modal = useRef();
  const closeModal = () => {
    setPopup(false);
    document.querySelector("html").classList.remove("scroll-lock");
  };
 

  useEffect(()=>{
    document.querySelector("html").classList.add("scroll-lock");
  }, [])

  return (
    <>
      <HCPRampContent
        buttonRef={closeButton}
        closeModal={closeModal}
        link1={link1}
        link2={link2}
        modalRef={modal}
      />
    </>
  );
};

export default HCPRamp;
