import React from "react";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";

import { Container, Grid, H4, Button } from "styles";

const Article = ({ title, content, link }) => {
  const clean = (dirty) => sanitizeHtml(dirty);

  return (
    <Container>
      <Grid>
        <GridItem>Video Placeholder</GridItem>
        <GridItem>
          <H4>{title}</H4>
          <div dangerouslySetInnerHTML={{ __html: clean(content) }} />
          <Button type="button">see products</Button>
        </GridItem>
      </Grid>
    </Container>
  );
};
const GridItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding:1rem;
  &:first-child {
    background: var(--muted-color);
    height: 250px;
    width: 100%;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  @media (min-width: 1150px) {
    &:first-child {
      grid-column: 2 / span 5;
      height:320px;
    }
    &:last-child {
      grid-column: 7 / span 5;
      margin-left:2rem;
    }
  }
  p {
    width: 100%;
    margin-bottom: 1em;
    font-size: 1rem;
    line-height: 1.25em;
    font-family: "Helvetica Neue";
    font-weight: 400;
    color: var(--primary-color);
    text-align: ${({ centered }) => (centered ? "center" : "left")};
  }
`;

export default Article;
