import React from "react";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";
import { H3, Button, P } from "styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ExitRamp } from "components";
import { Link } from "gatsby";
import { motion } from "framer-motion";

const PostCard = ({
  image,
  title,
  category,
  description,
  link,
  linkText,
  internalLink,
}) => {
  const clean = (dirty) => sanitizeHtml(dirty);
  const thumbnail = getImage(image);

  return (
    <GridItem initial={{opacity: 0, y: '2em'}} animate={{opacity: 1, y: 0}} exit={{opacity: 0, y: '2em'}}>
      <GridImage>
        <GatsbyImage image={thumbnail} alt={title} />
      </GridImage>
      <Caption>
        {category && (
          <P>
            <small>{category}</small>
          </P>
        )}
        {title && <H3>{title}</H3>}
        <div dangerouslySetInnerHTML={{ __html: clean(description) }} />
        <div style={{ marginTop: "auto" }}>
          {link && (
            <ExitRamp
              trigger={<StyledButton type="button">{linkText}</StyledButton>}
              modalLink={link}
            />
          )}
          {internalLink && (
            <Link to={internalLink}>
              <StyledButton>{linkText}</StyledButton>
            </Link>
          )}
        </div>
      </Caption>
    </GridItem>
  );
};

const StyledButton = styled(Button)`
  width: 10rem;
  height: 3rem;
  transition: 0.5s;
  margin: 0;
  justify-self: flex-end;
  background: var(--teva-blue);
  cursor: pointer;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  &:hover {
    background: var(--primary-color);
    color: white;
  }
`;

const Caption = styled.div`
  padding: 2em 1em;
  padding-top: 0.625em;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${H3} {
    margin-bottom: 0.5em;
    font-weight: 300;
  }
  ${P} {
    color: #3bb2f4;
    margin-bottom: 0.5em;
  }
  p {
    width: 100%;
    font-size: 1rem;
    line-height: 1.25em;
    font-family: "Helvetica Neue";
    font-weight: 300;
    color: var(--primary-color);
    text-align: left;
    margin-bottom: 2em;
  }
`;

const GridImage = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    border: 2px solid var(--muted-color);
    img {
      object-fit: contain !important;
    }
  }
`;

const GridItem = styled(motion.div)`
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 0 auto;
  background: rgba(0, 127, 200, 0.1);
  @media (min-width: 768px) {
    height: 100%;
  }
`;

export default PostCard;
