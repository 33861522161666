import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { FeatureButton, FeatureItem } from "components";
import FeatureAdapter from "../../images/feature-adapter.png";
import FeatureFlap from "../../images/feature-flap.png";
import FeatureSeal from "../../images/feature-seal.png";
import { useWindowSize } from "hooks";
import { graphql, useStaticQuery } from "gatsby";

const Features = () => {
  const { windowWidth } = useWindowSize();
  const data = useStaticQuery(graphql`
    query {
      featureAdapter: file(relativePath: { eq: "feature-adapter.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      featureFlap: file(relativePath: { eq: "feature-flap.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      featureSeal: file(relativePath: { eq: "feature-seal.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
    }
  `)
  return (
    <>
      {windowWidth >= 768 && (
        <Product>
          <MainImage
            initial={{ opacity: 0, rotate: -45 }}
            animate={{ opacity: 1, rotate: 0 }}
            transition={{ duration: 0.75 }}
          >
            <Background
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
            />
            <StaticImage
              src="../../images/aerochamber-features.png"
              alt="AeroChamber®"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="contain"
              className="product-image"
            />
            <FeatureButton
              top="5rem"
              right="8rem"
              width="20rem"
              content="<p>Universal Adapter that fits the most commonly prescribed metered dose inhalers.</p>"
              image={data.featureAdapter}
            />
            <FeatureButton
              top="12rem"
              left="16rem"
              width="20rem"
              content="<p>Flap moves when the patient inhales so caregiver can visually monitor if the patient is inhaling correctly</p>"
              image={data.featureFlap}
            />
            <FeatureButton
              top="16rem"
              left="0rem"
              width="25rem"
              content="<p>The device has not been made (or manufactured) with BPA (bisphenol A) or latex</p>"
              image={data.featureSeal}
            />
            <FeatureButton
              bottom="6rem"
              left="14rem"
              width="25rem"
              content="<p>ComfortSeal® Mask, soft and comfortable face mask that provides a critical seal. Available in 3 sizes to help provide the right fit</p>"
              svg="bpa"
            />
            <FeatureButton
              bottom="10rem"
              right="7rem"
              width="25rem"
              content="<p>AeroChamber Plus® Flow-Vu® is dishwasher safe on the top rack at temperatures up to 158°F (70°C).</p>"
              svg="safety"
            />
          </MainImage>
        </Product>
      )}
      {
        windowWidth < 768 && (
          <FeatureList>
          <FeatureItem
          top="5rem"
          right="8rem"
          width="20rem"
          content="<p>Universal Adapter that fits the most commonly prescribed metered dose inhalers.</p>"
          image={data.featureAdapter}
        />
        <FeatureItem
          top="12rem"
          left="16rem"
          width="20rem"
          content="<p>Flap moves when the patient inhales so caregiver can visually monitor if the patient is inhaling correctly</p>"
          image={data.featureFlap}
        />
        <FeatureItem
          top="16rem"
          left="0rem"
          width="25rem"
          content="<p>The device has not been made (or manufactured) with BPA (bisphenol A) or latex</p>"
          image={data.featureSeal}
        />
        <FeatureItem
          bottom="6rem"
          left="14rem"
          width="25rem"
          content="<p>ComfortSeal® Mask, soft and comfortable face mask that provides a critical seal. Available in 3 sizes to help provide the right fit</p>"
          svg="bpa"
        />
        <FeatureItem
          bottom="10rem"
          right="7rem"
          width="25rem"
          content="<p>AeroChamber Plus® Flow-Vu® is dishwasher safe on the top rack at temperatures up to 158°F (70°C).</p>"
          svg="safety"
        />
        </FeatureList>
        )
      }
    </>
  );
};


const FeatureList = styled.div`
grid-column: 1/7;
display:grid;
grid-template-columns: 1fr;
grid-row-gap: 2rem;
align-items:center;
justify-content: center;
margin: 2rem auto;
`

const Background = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: -1;
  background: var(--muted-color);
  border-radius: 50%;
`;

const MainImage = styled(motion.div)`
  width: 38rem;
  max-width: 100%;
  height: auto;
  position: relative;
  .gatsby-image-wrapper {
    width: 40rem !important;
    height: 40rem !important;
    max-width: 100%;
    max-height: 100%;
    img {
      object-fit: contain;
    }
  }
  @media (min-width: 1150px) {
    width: 40rem;
  }
`;

const Product = styled.div`
  width: 38rem;
  height: 38rem;
  margin: 0 auto;
  position: relative;
  @media (min-width: 768px) {
    grid-column: 1/13;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  width: 40rem;
  height: 40rem;
}
`;

export default Features;
