import * as React from "react";

function SvgClouds(props) {
  return (
    <svg
      viewBox="0 0 332 339"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M285.801 276.535c-.63 0-1.324.063-1.891.19a13.577 13.577 0 00-12.108-7.988c-2.964-.064-5.738.887-8.009 2.472-1.765-5.832-7.062-10.08-13.431-10.207-7.315-.127-13.369 5.326-14.252 12.49-.441-.064-.82-.064-1.261-.127-5.928-.064-10.783 4.628-10.847 10.524-.126 5.896 4.604 10.778 10.468 10.905a10.524 10.524 0 007.82-3.234c2.144 2.663 5.36 4.375 9.081 4.438 4.035.064 7.567-1.902 9.774-4.882 2.459 2.917 6.117 4.819 10.216 4.882a13.415 13.415 0 009.837-4.057c1.261.824 2.712 1.268 4.351 1.331 4.604.063 8.387-3.614 8.45-8.242.063-4.628-3.594-8.432-8.198-8.495zM169.801 146.535c-.63 0-1.324.063-1.891.19a13.577 13.577 0 00-12.108-7.988c-2.964-.064-5.738.887-8.009 2.472-1.765-5.832-7.062-10.08-13.431-10.207-7.315-.127-13.369 5.326-14.252 12.49-.441-.064-.82-.064-1.261-.127-5.928-.064-10.783 4.628-10.847 10.524-.126 5.896 4.604 10.778 10.468 10.905a10.524 10.524 0 007.82-3.234c2.144 2.663 5.36 4.375 9.081 4.438 4.035.064 7.567-1.902 9.774-4.882 2.459 2.917 6.117 4.819 10.216 4.882a13.415 13.415 0 009.837-4.057c1.261.824 2.712 1.268 4.351 1.331 4.604.063 8.387-3.614 8.45-8.242.063-4.628-3.594-8.432-8.198-8.495z"
        fill="#005A85"
        fillOpacity={0.2}
      />
      <path
        d="M128.801 87.005c-6.5-.1-12.2 3.1-15.7 8-.1-.2-.2-.3-.4-.5.6-2.2.9-4.4.9-6.7.2-15.7-12.2-28.5-27.9-28.8-11-.2-20.6 5.9-25.4 14.9-2.6-.8-5.3-1.3-8.2-1.4-13.4-.2-24.8 9.1-27.6 21.7-2.3-1.2-4.9-1.9-7.6-2-9.2-.1-16.8 7.2-16.9 16.4-.1 9.2 7.2 16.8 16.4 16.9 5.8.1 10.9-2.8 14-7.2 5 6 12.5 9.8 20.9 9.9 7.8.1 15-3 20.1-8.1 4 7.8 12 13.3 21.4 13.4 10 .2 18.7-5.7 22.6-14.3 3.3 3.2 7.8 5.2 12.8 5.3 10.4.2 18.9-8.1 19.1-18.5.1-10.3-8.2-18.8-18.5-19z"
        fill="#fff"
      />
      <path
        d="M283.125 303.963c-1.144 0-2.402.114-3.432.342-3.775-8.331-12.127-14.151-21.966-14.379-5.378-.114-10.412 1.598-14.53 4.451a25.948 25.948 0 00-24.37-18.373c-13.271-.228-24.254 9.586-25.856 22.481-.801-.114-1.487-.114-2.288-.228-10.755-.114-19.564 8.33-19.679 18.943-.228 10.613 8.352 19.4 18.992 19.628a19.162 19.162 0 0014.187-5.82c3.89 4.793 9.725 7.875 16.475 7.989 7.322.114 13.729-3.424 17.733-8.787 4.462 5.249 11.098 8.673 18.535 8.787 6.979.114 13.386-2.739 17.847-7.304 2.289 1.484 4.92 2.283 7.895 2.397 8.352.114 15.216-6.505 15.331-14.836.114-8.33-6.522-15.177-14.874-15.291zM316.004 29.203c-1.9 0-3.8.4-5.4 1.1-3-8.6-11.2-14.8-20.8-14.6-2.3 0-4.4.4-6.5 1.1-3.7-9.7-13.2-16.5-24.2-16.3-10.7.2-19.7 6.9-23.3 16.2-2.9-1.1-6.1-1.8-9.4-1.7-9.8.2-18.1 6-21.9 14.4-1.9-.8-4-1.3-6.2-1.2-8.3.1-14.9 6.9-14.7 15.2.1 8.3 6.9 14.9 15.2 14.7 3.5-.1 6.6-1.3 9.1-3.3 4.6 5.6 11.5 9.1 19.2 8.9 6.3-.1 12.1-2.6 16.3-6.7 4.4 4 10.3 6.3 16.7 6.2 7-.1 13.3-3.2 17.7-8 3.6 2.4 7.9 3.8 12.5 3.8 5.4-.1 10.3-2.2 14.1-5.5 2.8 3.5 7.1 5.8 12 5.7 8.3-.1 14.9-6.9 14.7-15.2 0-8.3-6.8-14.9-15.1-14.8z"
        fill="#E0ECF3"
      />
    </svg>
  );
}

export default SvgClouds;
