import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import sanitizeHtml from "sanitize-html";
import { H4, P } from "styles";
import { useIntersection } from "hooks";
import { RenderWhenReady } from "components";

const VideoCard = ({ title, description, link, embed, jobCode }) => {
  const clean = (dirty) => sanitizeHtml(dirty);
  const [elRef, observerEntry] = useIntersection({ threshold: 0 });

  return (
    <FlexItem href={link} target="_blank" rel="noreferrer">
      <Video
        ref={elRef}
        initial={{ opacity: 0 }}
        animate={observerEntry.isIntersecting ? { opacity: 1 } : { opacity: 0 }}
      >
        <iframe
          src={embed}
          title={title}
          id={title}
          allowFullScreen
          referrerPolicy="strict-origin"
          loading="lazy"
        />
      </Video>
      <P>
        <small>{jobCode}</small>
      </P>
      {title && <H4>{title}</H4>}
      <P dangerouslySetInnerHTML={{ __html: clean(description) }} />
    </FlexItem>
  );
};

const Video = styled(motion.div)`
  width: 100%;
  padding: 0 0 56.25% 0;
  position: relative;
  margin-bottom: 0.5rem;
  z-index: 2;
  transform-origin: center;
  transition-duration: 0.5s;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  border-radius: 0.3rem;
  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 0.3rem;
    border: none;
  }
`;

const FlexItem = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: relative;
  ${H4} {
    padding: 0 0.25rem;
    margin-bottom: 0.25em;
  }
  ${P} {
    font-size: 1rem;
    padding: 0 0.25rem;
    small {
      font-size: 0.75rem;
    }
  }
`;

export default VideoCard;
