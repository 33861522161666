import * as React from "react";

function SvgProductBackground(props) {
  return (
    <svg
      viewBox="0 0 540 542"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={272} cy={278} r={250} fill="#007FC8" fillOpacity={0.2} />
      <circle cx={250} cy={270} r={250} fill="#007FC8" fillOpacity={0.2} />
      <circle cx={290} cy={292} r={250} fill="#007FC8" fillOpacity={0.2} />
      <circle cx={286} cy={250} r={250} fill="#007FC8" fillOpacity={0.2} />
    </svg>
  );
}

export default SvgProductBackground;
