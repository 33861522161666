import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { H3, Button } from "styles";
import { ExitRamp } from "components";

const SponsorCard = ({ image, title, link }) => {
  return (
    <GridItem>
      <Image image={image} />
      <Caption>
        {title && <H3>{title}</H3>}
        <ExitRamp
          trigger={<Button type="button">Visit Site</Button>}
          modalLink={link}
        />
      </Caption>
    </GridItem>
  );
};

const Caption = styled.div`
  padding: 2em 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${H3} {
    margin-bottom: 0em;
    width: auto;
    text-align: center;
  }
`;

const Image = styled(motion.div)`
  width: 10rem;
  height: 10rem;
  min-height: 10rem;
  margin-bottom: 1rem;
  z-index: 2;
  border-radius: 50%;
  border: 0.5rem solid var(--teva-blue);
  background: "white";
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  box-shadow: 0 0 0 0 rgba(142, 173, 255, 0.1);
  animation: pulse 1.5s infinite;
  transform-origin: center;
  transition-duration: 0.5s;
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0.2rem rgba(0, 127, 200, 0.2),
        0 0 0 0.4rem rgba(0, 127, 200, 0.1);
    }

    50% {
      box-shadow: 0 0 0 0.375rem rgba(0, 127, 200, 0.2),
        0 0 0 0.75rem rgba(0, 127, 200, 0.1);
    }

    100% {
      box-shadow: 0 0 0 0.2rem rgba(0, 127, 200, 0.2),
        0 0 0 0.4rem rgba(0, 127, 200, 0.1);
    }
  }
`;

const GridItem = styled.div`
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 0 auto;
  &:nth-child(2) {
    ${Image} {
      background-color: var(--teva-blue);
    }
  }
  &:nth-child(3) {
    ${Image} {
      background-color: #009fe1;
      background-size: 60%;
    }
  }
  @media (min-width: 1150px) {
    &:nth-child(1) {
      grid-column: 2 / span 3.3;
    }
    &:nth-child(2) {
      grid-column: 5 / span 3.3;
    }
    &:nth-child(3) {
      grid-column: 8 / span 3.3;
    }
  }
`;

export default SponsorCard;
