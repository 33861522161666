// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clinical-info-js": () => import("./../../../src/pages/clinical-info.js" /* webpackChunkName: "component---src-pages-clinical-info-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-resources-healthcare-professionals-js": () => import("./../../../src/pages/resources/healthcare-professionals.js" /* webpackChunkName: "component---src-pages-resources-healthcare-professionals-js" */),
  "component---src-pages-resources-mask-sizing-js": () => import("./../../../src/pages/resources/mask-sizing.js" /* webpackChunkName: "component---src-pages-resources-mask-sizing-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */)
}

