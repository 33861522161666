import * as React from "react";

function SvgPlane(props) {
  return (
    <svg
      viewBox="0 0 96 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85.891 9.122c11.953 2.27 12.37 10.795 6.115 17.976-7.551-7.042-7.088-14.13-6.115-17.976z"
        fill="#F8F5E1"
      />
      <path
        d="M81.536 8.613c1.575.092 3.011.278 4.355.51-.973 3.845-1.436 10.933 6.116 17.975-3.429 3.938-8.85 7.413-15.475 9.405-24.461 7.274-55.734 2.965-66.204-1.668 9.358-1.76 66.668-13.065 71.208-26.222z"
        fill="#007FC8"
      />
      <path
        d="M7.409 28.905c5.281-1.112 44.754-9.683 63.425-28.03C78.71 3.1 81.536 8.614 81.536 8.614h.046C76.996 21.77 19.686 33.075 10.374 34.835c-2.131-.973-3.429-1.946-3.568-2.826-.139-.88.047-1.946.603-3.104z"
        fill="#F8F5E1"
      />
      <path
        d="M10.003 25.245c-.046-.046-.556-.602-.787-.834C3.517 18.25-2.598 9.17 1.154 6.435c3.892-2.78 17.42 2.456 23.582 8.108 5.235-2.965 11.768-6.255 19.783-9.776C56.61-.56 65.089-.7 70.788.876 52.163 19.222 12.69 27.793 7.408 28.906c.51-1.113 1.39-2.317 2.595-3.661z"
        fill="#007FC8"
      />
      <path
        d="M58.696 29.137c-4.587-4.216-21.358 4.586-21.822 10.053-.185 2.317 6.44 5.745 18.347 4.216 7.83-1.065 7.135-10.887 3.475-14.27z"
        fill="#F8F5E1"
      />
      <path
        d="M54.387 35.577c.417 2.918 2.038 5.142 3.614 4.91 1.575-.231 2.548-2.78 2.13-5.698-.416-2.919-2.038-5.143-3.613-4.911-1.575.232-2.502 2.78-2.131 5.699z"
        fill="#007FC8"
      />
      <path
        d="M75.606 12.226c.185 0 .37-.092.463-.185a14.125 14.125 0 002.502-2.502c.232-.324.232-.973-.046-1.343-2.085-2.687-4.865-4.216-6.348-4.911a1.665 1.665 0 00-.51-.093c-.323 0-.648.14-.88.324a69.577 69.577 0 01-1.899 1.668c-.139.14-.232.278-.232.464 0 .185.047.324.186.463 1.668 1.621 4.633 4.54 6.069 5.93.185.093.463.185.695.185zM67.545 16.998c.139 0 .231-.046.324-.092 1.946-.927 3.706-1.853 5.189-2.78.139-.093.231-.185.231-.324a.566.566 0 00-.185-.417A699.588 699.588 0 0167.128 7.5c-.186-.186-.417-.232-.695-.232a.595.595 0 00-.464.185c-1.112.834-2.27 1.622-3.52 2.456-.14.093-.279.278-.279.417-.046.185 0 .324.14.51a1631.81 1631.81 0 014.4 5.837c.14.185.51.37.835.324z"
        fill="#9DD1D6"
      />
    </svg>
  );
}

export default SvgPlane;
