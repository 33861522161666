import * as React from "react";

function SvgTevaLogoInverted(props) {
  return (
    <svg
      viewBox="0 0 202 246"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M201.992 90.983V246H0c91.354-18.419 164.907-74.665 201.992-155.017z"
        fill="#fff"
      />
      <path
        d="M200.014 14.958C185.798 121.022 113.605 218.186 0 246h.124c91.23-18.419 164.783-74.665 201.992-154.894V0c-.989 6.305-1.607 11.25-2.102 14.958z"
        fill="url(#teva-logo-inverted_svg__paint0_linear)"
      />
      <path
        d="M184.308 221.373c0 2.538-2 5.077-5.077 5.077-2.923 0-5.077-2.231-5.077-5.077 0-3.231 2.461-5.154 5-5.154 3.231.077 5.154 2.616 5.154 5.154zm-.385-9.077v1.077c-1.538-1.231-3.461-1.846-5.538-1.846-5.308 0-9.385 4.308-9.385 9.923 0 2.461.846 4.846 2.462 6.615 1.769 2 4.461 3.231 7.23 3.231 2 0 3.77-.615 5.231-1.846v1c0 .231.154.461.462.461h4.153c.231 0 .462-.153.462-.461v-18.154c0-.231-.154-.461-.462-.461h-4.153c-.308.076-.462.23-.462.461zM128.846 226.681c-1.154 0-2-.385-2.538-1.077-.385-.538-.616-1.231-.616-2.308v-7h5.385c.231 0 .385-.154.385-.384v-3.616c0-.23-.154-.384-.385-.384h-5.385v-4.846c0-.231-.154-.462-.461-.462h-4.308c-.231 0-.461.154-.461.462v4.846h-3.077c-.231-.077-.385.154-.385.384v3.539c0 .231.154.461.462.461h3.076v6.539c0 2.692.462 4.384 1.462 5.692 1.308 1.769 3.538 2.692 6.538 2.692 2.308 0 3.77-.538 4.539-1.153.231-.154.308-.616.154-.77-.077-.154-1.923-2.923-1.923-2.923-.154-.231-.539-.307-.77-.154-.307.154-1.076.462-1.692.462z"
        fill="#005A85"
      />
      <path
        d="M141.539 215.681c1.615 0 3.538 1.231 3.692 3.231h-7.692c.23-1.616 1.846-3.231 4-3.231zm0-4.231c-2.693 0-5.077 1.077-6.77 3-1.615 1.846-2.538 4.308-2.538 6.923 0 5.77 4.154 9.846 10.077 9.846 2.615 0 5.154-.923 7-2.461.077-.077.154-.154.307-.231a.473.473 0 00.077-.538c-.461-.616-1.538-2-2.077-2.693a.407.407 0 00-.615-.077c-.154.077-.231.231-.385.385-.846.692-2.23 1.539-4.076 1.539-2.693 0-4.77-1.77-5.077-4.385h12.692c.308 0 .615-.231.615-.539 0-.23.077-.538.077-.923 0-2.615-.923-5-2.538-6.846-1.923-1.923-4.231-3-6.769-3z"
        fill="#005A85"
      />
      <path
        d="M160.077 226.373v-2.692c0-1.077.231-2.385.692-3.616.462-1.461 1.231-2.692 2-3.538l1.231-1.538v6.384l-3.923 5z"
        fill="#00A070"
      />
      <path
        d="M163.923 214.912l2.539-3.231c.23-.231.538-.385.846-.231.307.154.538.385.538.769v4L164 221.296v-6.384h-.077z"
        fill="#B5D16C"
      />
      <path
        d="M167.846 216.219v2.308c.077 1.154-.154 2.539-.615 3.923-.462 1.231-1 2.385-1.693 3.231l-1.538 2v-6.385l3.846-5.077z"
        fill="#317575"
      />
      <path
        d="M163.923 221.296v6.385l-2.538 3.231c-.231.231-.539.384-.847.231-.307-.154-.538-.385-.538-.77v-4.077l3.923-5z"
        fill="#005A85"
      />
      <path
        d="M159.538 226.373v-2.692c0-1.077-.23-2.385-.692-3.616-.461-1.461-1.231-2.692-2-3.538l-1.231-1.538v6.384l3.923 5z"
        fill="#00A070"
      />
      <path
        d="M155.692 214.912l-2.538-3.231c-.231-.231-.539-.385-.846-.231-.308.154-.539.385-.539.769v4l3.846 5.077v-6.384h.077z"
        fill="#B5D16C"
      />
      <path
        d="M151.846 216.219v2.308c-.077 1.154.154 2.539.616 3.923.461 1.231 1 2.385 1.692 3.231l1.538 2v-6.385l-3.846-5.077z"
        fill="#317575"
      />
      <path
        d="M155.692 221.296v6.385l2.539 3.231c.231.231.538.384.846.231.308-.154.538-.385.538-.77v-4.077l-3.923-5z"
        fill="#005A85"
      />
      <defs>
        <linearGradient
          id="teva-logo-inverted_svg__paint0_linear"
          x1={0}
          y1={122.989}
          x2={202.048}
          y2={122.989}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009B28" />
          <stop offset={0.48} stopColor="#56B444" />
          <stop offset={0.832} stopColor="#8FC557" />
          <stop offset={1} stopColor="#A5CB5E" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgTevaLogoInverted;
