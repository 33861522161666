import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import { Container, Grid, Button } from "styles";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query TestimonialsQuery {
      image1: file(relativePath: { eq: "testimonial-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  const slides = [
    {
      id: 1,
      quote: `Testimonial - Using AeroChamber® helped me... Lorem ipsum dolor sit onet consecitur decim et utem de untem etiam. Lorem ipsum dolor sit onet consecitur decim et utem de untem etiam.`,
      author: `John Doe`,
      image: getImage(data.image1),
    },
  ];

  const [page, direction] = useState([0, 0]);
  // const paginate = useCallback(
  //   (dir) => {
  //     setPage([page + dir, dir]);
  //   },
  //   [page]
  // );

  // useEffect(() => {
  //   const timer = setTimeout(() => paginate(1), 3000);
  //   return () => clearTimeout(timer);
  // }, [paginate]);

  const index = wrap(0, slides.length, page);

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 300 : -300,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 300 : -300,
        opacity: 0,
      };
    },
  };

  return (
    <Container style={{ overflow: "hidden" }}>
      <SlideGrid>
        <AnimatePresence initial={false} custom={direction}>
          <Slide
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: 0.5 }}
          >
            <GatsbyImage
              image={slides[index].image}
              alt={slides[index].author}
            />
            <Caption>
              <Quote>{slides[index].quote}</Quote>
              <Author>- {slides[index].author}</Author>
              <LinkButton>Read Full Story</LinkButton>
            </Caption>
          </Slide>
        </AnimatePresence>
      </SlideGrid>
    </Container>
  );
};

const LinkButton = styled(Button)`
  width: 200px;
  background: var(--teva-green);
  margin-bottom: 0;
`;

const Author = styled.p`
  color: var(--primary-color);
  font-size: 1rem;
  line-height: 1.3em;
  font-style: italic;
  font-weight: 700;
  align-self: flex-end;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Quote = styled.blockquote`
  color: var(--primary-color);
  font-size: 1rem;
  line-height: 1.3em;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 0;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Caption = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(151, 211, 38, 0.1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

const Slide = styled(motion.div)`
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  grid-template-columns: 1fr;
  position: absolute;
  top: 0;
  left: 0;
  @media (min-width: 768px) {
    grid-column: 1/13;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1150px) {
    grid-template-columns: 1fr 1fr;
  }
  .gatsby-image-wrapper {
    max-height: 100%;
    padding: 4rem;
  }
`;

const SlideGrid = styled(Grid)`
  height: 550px;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    height: 450px;
  }
  @media (min-width: 1150px) {
    height: 350px;
  }
`;

export default Testimonials;
