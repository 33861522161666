import React from "react";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";
import { VideoCard } from "components";
import { ThreePieceGrid } from "styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import { Container, Grid, H2, Button } from "styles";

const ProductDetails = ({ product, videos }) => {
  const clean = (dirty) => sanitizeHtml(dirty);
  const mainImage = getImage(product.mainImg);
  const thumb1 = getImage(product.thumb1);
  const thumb2 = getImage(product.thumb2);
  const thumb3 = getImage(product.thumb3);
  const maskVideos = videos.filter((video) => video.type !== "mouthpiece");
  const mouthpieceVideos = videos.filter((video) => video.type !== "mask");

  return (
    <>
      {product && (
        <Container>
          <Grid key={product.id}>
            <GridItem>
              <MainImage
                initial={{ opacity: 0, rotate: -90 }}
                animate={{ opacity: 1, rotate: 0 }}
                transition={{ duration: 0.75 }}
              >
                <GatsbyImage image={mainImage} alt={product.name} />
              </MainImage>
              <Thumbnails>
                <Thumb>
                  <GatsbyImage image={thumb1} alt={product.name} />
                </Thumb>
                <Thumb>
                  <GatsbyImage image={thumb2} alt={product.name} />
                </Thumb>
                <Thumb>
                  <GatsbyImage image={thumb3} alt={product.name} />
                </Thumb>
              </Thumbnails>
            </GridItem>

            <GridItem
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
            >
              <H2 dangerouslySetInnerHTML={{ __html: clean(product.name) }} />
              <Content
                dangerouslySetInnerHTML={{ __html: clean(product.description) }}
              />
              <Button type="button" aria-label="Download Instructions">
                <a
                  href={product.instructionsLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  download instructions
                </a>
              </Button>
            </GridItem>
          </Grid>
          <H2 centered style={{ padding: "0 1rem" }}>
            Instructional Videos
          </H2>
          <ThreePieceGrid>
            {product.hasMask
              ? maskVideos.map((video, i) => (
                  <>
                    <VideoCard
                      key={i}
                      thumbnail={video.thumbnail}
                      title={video.title}
                      description={video.description}
                      jobCode={video.jobCode}
                      link={video.link}
                      embed={video.embed}
                    />
                  </>
                ))
              : mouthpieceVideos.map((video, i) => (
                  <>
                    <VideoCard
                      key={i}
                      thumbnail={video.thumbnail}
                      title={video.title}
                      description={video.description}
                      jobCode={video.jobCode}
                      link={video.link}
                      embed={video.embed}
                    />
                  </>
                ))}
          </ThreePieceGrid>
        </Container>
      )}
    </>
  );
};

const Thumb = styled.div`
  width: 100%;
  height: auto;
  .img {
    object-fit: contain !important;
  }
`;

const Thumbnails = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.25rem;
  @media (min-width: 768px) {
    grid-gap: 1rem;
  }
`;

const Content = styled.div`
  p,
  ul {
    width: 100%;
    margin-bottom: 1em;
    font-size: 1rem;
    line-height: 1.5em;
    font-family: "Helvetica Neue";
    font-weight: 300;
    color: var(--primary-color);
    text-align: ${({ centered }) => (centered ? "center" : "left")};
  }
  h4 {
    color: var(--primary-color);
    font-weight: 300;
    margin-bottom: 1rem;
    margin-top: -1rem;
  }
  ul {
    padding-left: 2rem;
    li {
      margin: 0.25em 0;
    }
  }
  button {
    align-self: flex-start;
  }

  @media (min-width: 1150px) {
    p {
      font-size: 1.25rem !important;
    }
    ul {
      font-size: 1.15rem;
    }
  }
`;

const MainImage = styled(motion.div)`
  width: 100%;
  padding: 1rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    overflow: visible;
    img {
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-55%, -50%) rotate(-90deg);
      object-fit: contain !important;
      width: 100%;
      height: 100%;
    }
  }
`;

const GridItem = styled(motion.div)`
  grid-column: 1/7;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  ${Button} {
    align-self: flex-start;
    a {
      cursor: pointer;
      color: white !important;
    }
  }
  &:first-child {
    grid-area: 2/ 1 / span 1 / span 7;
    background: white;
    height: auto;
    margin-top: -3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  &:last-child {
    grid-area: 1/ 1 / span 1 / span 7;
  }

  @media (min-width: 768px) {
    padding: 2rem;
    &:first-child {
      grid-area: 1/ 1 / span 1 / span 6;
      height: 400px;
      margin-top: 0;
      .gatsby-image-wrapper {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &:last-child {
      grid-area: 1/ 7 / span 1 / span 6;
    }
  }
  @media (min-width: 1150px) {
    &:first-child {
      grid-area: 1/ 2 / span 1 / span 5;
      .gatsby-image-wrapper {
        img {
          width: 120%;
          height: 120%;
        }
      }
    }
    &:last-child {
      grid-area: 1/ 7 / span 1 / span 5;
      margin-left: 2rem;
    }
  }
`;

export default ProductDetails;
