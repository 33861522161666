import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { H2 } from "styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ProductBackground, YouthMouthpieceBg } from "images/svgs";

const YouthMouthpiece = ({ image }) => {
  return (
    <Product>
      <PrimaryBackground
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <ProductBackground />
      </PrimaryBackground>
      <SecondaryBackground
        initial={{ opacity: 0, scale: 0.75, y: "-50%", x: "-50%" }}
        animate={{ opacity: 1, scale: 1, y: "-50%", x: "-50%" }}
        transition={{ duration: 0.75, delay: 0.25 }}
      >
        <YouthMouthpieceBg />
      </SecondaryBackground>
      <MainImage
        initial={{ opacity: 0, rotate: 180, y: "-50%", x: "-50%" }}
        animate={{ opacity: 1, rotate: 235, y: "-50%", x: "-50%" }}
        transition={{ duration: 0.75 }}
      >
        <GatsbyImage
          image={getImage(image)}
          alt="AeroChamber Youth Mouthpiece"
          className="product-image"
        />
      </MainImage>
      <Caption
        initial={{ transform: "rotateX(90deg) perspective(300px)" }}
        animate={{ transform: "rotateX(0deg) perspective(0px)" }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <H2>Youth Mouthpiece</H2>
      </Caption>
    </Product>
  );
};

const Caption = styled(motion.div)`
  position: absolute;
  top: 85%;
  right: -10%;
  z-index: 3;
  width: auto;
  max-width: 220px;
  ${H2} {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.25em;
    margin-bottom: 0;
    width: auto;
    span {
      font-size: 0.75em;
    }
  }
  @media (min-width: 1150px) {
    top: 80%;
    ${H2} {
      font-size: 2rem;
    }
  }
`;

const SecondaryBackground = styled(motion.div)`
  width: 90%;
  height: auto;
  position: absolute;
  top: 52.55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const PrimaryBackground = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 1;
  svg {
    width: 500px;
    max-width: 100%;
    height: 100%;
  }
  width: 100%;
  @media (min-width: 1150px) {
    width: 120%;
  }
`;

const MainImage = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 100%;
  padding-bottom: 100%;
  .gatsby-image-wrapper {
    width: 120% !important;
    height: 120% !important;
    overflow: visible;
    position: absolute;
    top: -25%;
    left: -5%;
    max-width: 120%;
    max-height: 120%;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  max-width: 100%;
`;

const Product = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  @media (min-width: 768px) {
    width: 90%;
    height: 90%;
  }
  @media (min-width: 1150px) {
    width: 100%;
    height: 100%;
  }
`;

export default YouthMouthpiece;
