import React, { useState, useEffect } from "react";
import {
  Logo,
  // FacebookIcon,
  // TwitterIcon,
  // LinkedinIcon,
  // InstaIcon,
} from "images/svgs";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useWindowSize } from "hooks";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Dropdown } from "components";

const Navbar = () => {
  // const route = typeof window !== "undefined" ? window.location.pathname : "";
  const { windowWidth } = useWindowSize();
  const isMobile = windowWidth < 768;
  const [navOpen, setNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  // const [activeLink, setActiveLink] = useState(route !== "" ? route : "/");

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  // const toggleActiveLink = (link) => {
  //   toggleNav();
  //   setActiveLink(link);
  // };

  return (
    <>
      <NavBg scrolled={isScrolled} />
      <Container scrolled={isScrolled} isMobile={isMobile}>
        <LogoButton to="/" title="Homepage">
          <Logo alt="Logo" />
        </LogoButton>
        <AnimatePresence>
          {(!isMobile || navOpen) && (
            <Links
              navOpen={navOpen}
              initial={
                isMobile ? { opacity: 1, x: 400 } : { opacity: 0, y: -50 }
              }
              animate={isMobile ? { opacity: 1, x: 0 } : { opacity: 1, y: 0 }}
              exit={isMobile ? { opacity: 1, x: 400 } : { opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              <div>
                {isMobile && (
                  <CustomLink
                    // className={activeLink === "/home/" ? "active " : null}
                    to="/"
                    onAnchorLinkClick={toggleNav}
                  >
                    Home
                  </CustomLink>
                )}
                <CustomLink
                  // className={activeLink === "/home/" ? "active " : null}
                  to="/products/"
                  onAnchorLinkClick={toggleNav}
                >
                  Products
                </CustomLink>
                <CustomLink
                  // className={activeLink === "/home/" ? "active " : null}
                  to="/resources/healthcare-professionals#hcp"
                  onAnchorLinkClick={toggleNav}
                >
                  Healthcare Professionals
                </CustomLink>
                {/* <Dropdown toggleNav={toggleNav} /> */}
                <CustomLink
                  // className={activeLink === "/about/" ? "active " : null}
                  to="/contact/"
                  onAnchorLinkClick={toggleNav}
                >
                  Contact
                </CustomLink>
              </div>
              {/* {isMobile && (
                <Socials>
                  <Social href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                    <FacebookIcon />
                  </Social>
                  <Social href="https://twitter.com/" target="_blank" rel="noreferrer">
                    <TwitterIcon />
                  </Social>
                  <Social
                    href="https://www.linkedin.com/company/"
                    target="_blank" rel="noreferrer"
                  >
                    <LinkedinIcon />
                  </Social>
                  <Social href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                    <InstaIcon />
                  </Social>
                </Socials>
              )} */}
            </Links>
          )}
        </AnimatePresence>
        {isMobile && (
          <MenuToggle
            navOpen={navOpen}
            onClick={toggleNav}
            aria-label="Toggle Navigation"
          >
            <span />
          </MenuToggle>
        )}
      </Container>
    </>
  );
};

const MenuToggle = styled.button`
  grid-area: toggle;
  grid-column: 6/7;
  margin: 1rem;
  height: 1.75rem;
  width: 1.75rem;
  border: 2px solid var(--primary-color);
  border-radius: 0.5rem;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:focus {
    border: 2px solid var(--primary-color);
  }
  span {
    content: "";
    background: var(--primary-color);
    height: 2px;
    width: 0.75rem;
    display: block;
    position: relative;
    transition: 0.375s;
    margin-top: -0.75rem;
    transform: ${({ navOpen }) =>
      navOpen ? "translateY(6px) rotate(45deg)" : "none"};
    :before,
    :after {
      content: "";
      display: block;
      height: 2px;
      width: 0.75rem;
      position: absolute;
      background: var(--primary-color);
      transition: 0.375s;
      transform-origin: center;
      opacity: 1;
    }
    :before {
      top: 6px;
      opacity: ${({ navOpen }) => (navOpen ? 0 : 1)};
    }
    :after {
      top: 12px;
      transform: ${({ navOpen }) =>
        navOpen ? "translateY(-12px) rotate(-90deg)" : "none"};
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

// const Socials = styled.div`
//   display: flex;
//   margin-top: 5rem;
// `;

// const Social = styled.a`
//   background: black;
//   border-radius: 50%;
//   min-width: 42px;
//   height: 42px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 0.5rem;
//   transition: 0.75s;
//   svg {
//     width: 20px;
//     height: 20px;
//     max-width: 20px;
//     max-height: 20px;
//     transition: 0.75s;
//     path {
//       fill: white;
//     }
//   }
//   :hover {
//     background: var(--muted-color);
//     svg {
//       path {
//         fill: var(--primary-color);
//       }
//     }
//   }
// `;

const CustomLink = styled(AnchorLink)`
  font-weight: 700;
  font-size: 2rem;
  line-height: 1em;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  margin: 0.75em 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  div {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) {
    font-size: 1.1rem;
    padding-left: 3rem;
    height: 2rem;
    width: auto;
    color: var(--primary-color);

    justify-content: center;
    margin: 0;
  }
`;

const Links = styled(motion.div)`
  grid-area: links;
  grid-column: 1/7;
  display: ${({ navOpen }) => (navOpen ? "flex" : "none")};
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  opacity: 0;
  height: calc(100vh - 60px);
  background: white;
  backdrop-filter: blur(2px);
  padding: 2rem;

  > div {
    &:first-child {
      width: 100%;
      height: auto;
    }
  }
  @media (min-width: 768px) {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    background: transparent;
    backdrop-filter: none;
    grid-column: 4/12;

    > div {
      &:first-child {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

const LogoButton = styled(AnchorLink)`
  grid-area: logo;
  grid-column: 1/5;
  height: auto;
  width: auto;
  max-width: 12rem;
  padding: 1rem;
  svg {
    width: 20rem;
    max-width: 100%;
    height: auto;
  }
  @media (min-width: 768px) {
    grid-column: 2/7;
    padding: 1rem;
  }
  @media (min-width: 1150px) {
    max-width: 20rem;
    grid-column: 2/5;
    padding: 1rem;
  }
`;

const NavBg = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    background: white;
    transition: 0.375s;
    height: ${({ scrolled }) => (scrolled ? "7rem" : "10rem")};
    box-shadow: ${({ scrolled }) =>
      scrolled ? "0px 5px 12px rgba(0, 0, 0, 0.15)" : "none"};
  }
`;

const Container = styled.nav`
  width: 100vw;
  max-width: 100%;
  height: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: "logo toggle" "links links";
  align-items: center;
  justify-content: space-between;
  background: white;
  transition: 0.375s;
  grid-column-gap: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1.25rem;
    grid-template-areas: "logo links";
    height: ${({ scrolled }) => (scrolled ? "7rem" : "10rem")};
    box-shadow: ${({ scrolled }) =>
      scrolled ? "0px 5px 12px rgba(0, 0, 0, 0.15)" : "none"};
  }
  @media (min-width: 1150px) {
    max-width: 1440px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: none;
  }
`;

export default Navbar;
