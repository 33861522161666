import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Container, Grid, H2, P, Button } from "styles";

const ArticleBanner = ({ article }) => {
  const image = getImage(article.mainImg);
  return (
    <Grid style={{ margin: "0 auto", gridGap: '0' }}>
      <BannerContainer>
        <Banner>
          <div style={{ position: "relative" }}>
            <GatsbyImage image={image} alt={article.title} />
          </div>
          <Caption>
            <H2>{article.title}</H2>
            <P>{article.shortDescription}</P>
            <Link to={`/articles/${article.slug}`}>
              <StyledButton type="button">READ FULL STORY</StyledButton>
            </Link>
          </Caption>
        </Banner>
      </BannerContainer>
    </Grid>
  );
};

const Caption = styled.div`
  padding: 2rem 2.75rem;
  ${H2} {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.25em;
    margin-bottom: 0.5rem;
    margin-top: 0rem;
    width: auto;
  }
  ${P} {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
  }
  @media (min-width: 1150px) {
    ${H2} {
      font-size: 2rem;
    }
  }
`;
const BannerContainer = styled(Container)`
  width: 100%;
  grid-column: 1/7;
  @media (min-width: 768px) {
    grid-column: 2/12;
    max-height: 360px;
  }
`;

const StyledButton = styled(Button)`
  transition: 0.5s;
  justify-self: flex-end;
  background: #007AC0;
  cursor: pointer;
  margin-top: 0.25rem !important;
  margin-bottom: 0;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: var(--primary-color);
    color: white;
  }
`;

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: rgba(0, 127, 200, 0.1);
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    div {
      width: 100%;
      height: auto;
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        position: absolute !important;
        top: 0;
        left: 0;
      }
    }
  }
`;

export default ArticleBanner;
