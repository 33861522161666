import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { H3, P, Button } from "styles";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const ExitRampContent = (props) => {
  return ReactDOM.createPortal(
    <ModalCover
      tag="aside"
      role="dialog"
      tabIndex="-1"
      aria-modal="true"
      className="modal-cover"
      onKeyDown={props.onKeyDown}
    >
      <AnimatePresence initial="false">
        <ModalArea
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.375 }}
          onClick={props.onClickOutside}
        >
          <ModalBody ref={props.modalRef} onClick={(e) => e.stopPropagation()}>
            {/* <ModalClose
            ref={props.buttonRef}
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            onClick={props.closeModal}
            colors={colors}
          >
            <HideVisual id="close-modal">Close</HideVisual>
            <ModalCloseIcon colors={colors} viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </ModalCloseIcon>
          </ModalClose> */}
            <H3>You are now leaving this website</H3>
            <P>
              This website may contain links or references to other websites
              maintained by Teva Pharmaceuticals Ireland, ("Teva"), or the links
              may be to websites maintained by third parties over whom Teva has
              no control. Links are provided as a convenient resource to our
              visitors. We carefully choose websites which we believe are useful
              based on our high standards. However, websites can change. Teva
              cannot guarantee the standards of every website link or be
              responsible for the content of non-Teva websites. Inclusion of any
              third-party link does not imply an endorsement or recommendation
              by Teva.
              <br />
              <br />
              Similarly, this website may be accessed from third-party links
              over which Teva has no control. Teva makes no warranties or
              representations of any kind as to the accuracy, currency, or
              completeness of any information contained in such websites and
              shall have no liability for any damages or injuries of any kind
              arising from such content or information.
            </P>
            <Links>
              <Button
                onClick={props.closeModal}
                className="stay"
                aria-label="Go Back"
              >
                Go Back
              </Button>
              <OutboundLink href={props.link} target="_blank" rel="noreferrer">
                <Button type="button" className="leave" aria-label="Continue">
                  Continue
                </Button>
              </OutboundLink>
            </Links>
            <P style={{ margin: 0 }}>
                <small>
                M: AER-IE-00001 (2.0) / T: AER-IE-00018 (V1.0) M: AER-IE-00019 (V1.0) | Date of preparation: July 2023
                </small>
              </P>
          </ModalBody>
        </ModalArea>
      </AnimatePresence>
    </ModalCover>,
    document.body
  );
};

const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  ${Button} {
    cursor: pointer;
    opacity: 0.9;
    transition-duration: 0.375s;
    margin: 0.75rem 1rem;
    width: 10rem;
    &.leave {
      background: rgb(100, 200, 25);
    }
    &.stay {
      background: rgb(200, 25, 50);
    }
    &:hover {
      &.leave {
        background: var(--teva-green);
      }
      &.stay {
        background: var(--teva-red);
      }
    }
  }
`;

// const ModalClose = styled.button`
//   position: absolute;
//   top: 0;
//   right: 0;
//   padding: 0.5em;
//   line-height: 1;
//   background: white;
//   border: 0;
//   box-shadow: 0;
//   z-index: 20;
//   cursor: pointer;
//   border-radius: 0.25rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
//   svg {
//     path {
//       stroke: white;
//       fill: white;
//     }
//   }
// `;

// const ModalCloseIcon = styled.svg`
//   width: 20px;
//   height: 20px;
//   stroke-linecap: round;
//   stroke-width: 3;
//   path {
//     fill: black;
//   }
// `;

// const HideVisual = styled.span`
//   border: 0 !important;
//   clip: rect(0 0 0 0) !important;
//   height: 1px !important;
//   margin: -1px !important;
//   overflow: hidden !important;
//   padding: 0 !important;
//   position: absolute !important;
//   width: 1px !important;
//   white-space: nowrap !important;
// `;

const ModalBody = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  max-width: calc(100% - 2rem);
  background: white;
  color: var(--primary-color);
  height: auto;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.3rem;
  box-shadow: 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  ${H3} {
    color: var(--primary-color);
    font-weight: 300;
    text-align: center;
    margin-bottom: 1rem;
  }
  ${P} {
    color: var(--primary-color);

    text-align: center;
    font-size: 0.875rem;
    line-height: 1.5em;
  }
  @media (min-width: 1150px) {
    padding: 3rem;
  }
`;

const ModalArea = styled(motion.aside)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  color: white;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  padding: 20px;
`;

const ModalCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(255, 255, 255, 0.5);
`;

export default ExitRampContent;
