import React from "react";
import styled from "styled-components";
import { H4, P, Button } from "styles";
import { Link } from "gatsby";
import { Questions } from 'images/svgs';

const SizingCard = () => {
  return (
    <GridItem>
        <Questions />
      <div>
        <H4>Not sure which product size you need?</H4>
        <P>
          Download and print our sizing template to get a perfect fit for you
        </P>
      </div>
      <Link style={{ justifySelf: "end" }} to="/resources/mask-sizing">
        <Button>Mask Sizing</Button>
      </Link>
    </GridItem>
  );
};

const GridItem = styled.div`
  grid-column: 1/7;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  background: rgba(0, 127, 200, 0.1);
  padding: 1.5rem;
  padding-top: 8rem;
  margin-top: 4rem;
  ${H4} {
    margin: 0;
  }
  ${P} {
    @media (min-width: 1150px) {
      font-size: 1rem;
    }
  }
  ${Button} {
    margin: 1rem;
    cursor: pointer;
  }
  svg {
    position: absolute;
    width: 10rem !important;
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (min-width: 768px) {
      top: -3rem;
      left: 5rem;
    }
    @media (min-width: 1150px) {
      top: 0rem;
      left: 7rem;
    }
  }
  @media (min-width: 768px) {
    padding: 1rem 1.5rem;
    padding-left: 5rem;
    grid-template-columns: 2fr 1fr;
    grid-column: 1/13;
  }
  @media (min-width: 1150px) {
    padding: 1.5rem 2.5rem;
    padding-left: 15rem;
    grid-template-columns: 2fr 1fr;
    grid-column: 2/12;
  }
`;

export default SizingCard;
