import * as React from "react";

function SvgHcp(props) {
  return (
    <svg
      width="88"
      height="68"
      viewBox="0 0 88 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.5061 5.64355H32.9717C40.2591 5.64355 43.9029 10.7448 43.9029 16.5747V58.4776H40.2591C38.8016 56.6557 36.4332 54.8338 32.9717 54.8338H9.28748V5.64355H16.5749V23.8622L21.3118 20.2185L27.5061 23.8622V5.64355Z"
        fill="#FFC800"
        fill-opacity="0.4"
      />
      <path
        d="M67.587 49.3683C60.1174 49.3683 53.0121 40.4411 53.0121 32.9715C53.0121 25.5019 61.9393 16.5747 69.4089 16.5747C71.7773 16.5747 76.6964 19.8541 78.5182 20.9472V5.64355H71.2308H60.2996H54.834C47.5466 5.64355 43.9028 10.7448 43.9028 16.5747V58.4776H47.5466C49.004 56.6557 51.3725 54.8338 54.834 54.8338H78.5182V44.9958C76.6964 46.0889 69.9555 49.3683 67.587 49.3683Z"
        fill="#FFC800"
        fill-opacity="0.4"
      />
      <path
        d="M85.8057 20.2187V11.1094H78.5182"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M5.64372 11.1094H2V62.1215H32.9717L36.6154 65.7652H51.1903L54.834 62.1215H85.8057V45.7248"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M16.5749 5.64355H9.28748V54.8338H32.9717C36.4332 54.8338 38.8016 56.6557 40.2591 58.4776H43.9029V16.5747C43.9029 10.7448 40.2591 5.64355 32.9717 5.64355H27.5061"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M78.5182 14.7529V5.64355H54.834C47.5466 5.64355 43.9028 10.7448 43.9028 16.5747V58.4776H47.5466C49.004 55.7448 51.3725 54.8338 54.834 54.8338H78.5182V51.1901"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M16.5749 23.8624L22.0405 20.2186L27.5061 23.8624V2H16.5749V23.8624Z"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M69.4089 49.3685C78.4646 49.3685 85.8057 42.0274 85.8057 32.9717C85.8057 23.916 78.4646 16.575 69.4089 16.575C60.3532 16.575 53.0121 23.916 53.0121 32.9717C53.0121 42.0274 60.3532 49.3685 69.4089 49.3685Z"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M74.8744 40.2593H69.4089V29.3281H65.7651"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M63.9432 40.2593H69.4088"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M69.4089 25.6843V22.0405"
        stroke="#007FC8"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SvgHcp;
