import React from "react";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FeatureBPA, FeatureSafety } from "../../../images/svgs";

const FeatureItem = ({ content, image, svg }) => {
  const clean = (dirty) => sanitizeHtml(dirty);
  return (
    <Container>
      <Dot>
        {image && (
          <GatsbyImage
            image={getImage(image)}
            alt="Feature Image"
            objectFit="contain"
          />
        )}
        {svg && svg === "bpa" && <FeatureBPA />}
        {svg && svg === "safety" && (
          <FeatureSafety style={{ left: "12.5%", top: "12.5%" }} />
        )}
      </Dot>
      <Content dangerouslySetInnerHTML={{ __html: clean(content) }} />
    </Container>
  );
};

const Dot = styled(motion.div)`
  min-width: 2.5rem;
  width: 7.5rem;
  height: 7.5rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 2;
  border-radius: 50%;
  border: 0.25rem solid var(--teva-blue);
  background: white;
  box-shadow: 0 0 0 0 rgba(142, 173, 255, 0.1);
  animation: pulse 1s infinite;
  transform-origin: center;
  transition-duration: 0.5s;
  span {
    width: 0.875rem;
    height: 0.125rem;
    background: white;
    display: block;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:last-child {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  svg {
    position: absolute;
    display: block;
    top: 6%;
    left: 10%;
    width: 80%;
    height: 80%;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(142, 173, 255, 0.9);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  @media (max-width: 374px) {
    width: 6rem;
    height: 6rem;
    left: -0.5rem;
  }
`;

const Content = styled(motion.div)`
  width: 100%;
  height: 7.5rem;
  background: rgb(230, 245, 255);
  box-shadow: 4px 4px 10px rgba(0, 90, 133, 0.2);
  z-index: 1;
  padding: 1rem;
  padding-left: 5rem;
  border-radius: 5px;
  text-align: left;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  p {
    font-size: 0.875rem;
    line-height: 1.3em;
  }
  @media (max-width: 374px) {
    padding: 0.75rem;
    padding-left: 3rem;
    height: 6rem;
  }
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
  padding-left: 3.75rem;
  @media (max-width: 374px) {
    padding-left: 3rem;
  }
`;

export default FeatureItem;
